import React from "react";
import "../../css/footer.css";

function Footer() {
  return (
    <div className="footerContainer">
      <div className="footerInfo">
        <div className="InfoItem">
          <h1>Engineer Change.</h1>
          <p>
            UCLA Samueli School of Engineering Department of Civil and
            Environmental Engineering Los Angeles, CA 90095
          </p>
        </div>
        <div className="InfoItem">
          <h1> Contact </h1>
          <p>
            Sriram Narasimhan <br></br>Email: <a href="mailto:snarasim@ucla.edu">snarasim@ucla.edu</a>
          </p>
          <p>
            Ertugrul Taciroglu <br></br>Email: <a href="mailto:etacir@ucla.edu">etacir@ucla.edu</a>
          </p>
        </div>
      </div>

      <div className="footerBar">
        <div className="website-rights">© 2022 SRI Lab</div>
      </div>
    </div>
  );
}

export default Footer;
