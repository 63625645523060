import { useEffect, useState } from "react";
import Plot from "react-plotly.js";

/*
Scatter Plot Component

* Provides access to visualize geojson data in a scatter plot
* Stores selection in selectionKey for portability
* Updates data visualization with highlighted data
*/
export default function ScatterPlot({
  geoData,
  callback,
  width,
  height,
  yScale,
  xProperty,
  yProperty,
  title,
  selection,
  selectionIndices,
  setSelectionIndices,
  selectKey,
  setSelectKey,
}) {
  const [x, setX] = useState([]);
  const [y, setY] = useState([]);
  const [t, setT] = useState([]);
  const [xSel, setXSel] = useState([]);
  const [ySel, setYSel] = useState([]);
  const [tSel, setTSel] = useState([]);

  // Function to execute selection callback if selected data modified 
  function setSelection(selected) {
    if (selected != null && selected.points.length > 0) {
      const temp = selected.points
        .filter((p) => p.fullData.name === "trace 0")
        .map((p) => p.pointIndex)
        .map((index) => geoData[index]);
      callback(temp);
    }
  }

  // Updates selection data on map if underlying data is selected
  useEffect(() => {
    setX(
      geoData.map((d) => {
        return d.properties[xProperty];
      })
    );
    setY(
      geoData.map((d) => {
        return d.properties[yProperty];
      })
    );
    setT(
      geoData.map((d) => {
        return d.properties.ST_NAME;
      })
    );

    if (selection != null && selection.length > 0) {
      setXSel(
        selection.map((d) => {
          return d.properties[xProperty];
        })
      );
      setYSel(
        selection.map((d) => {
          return d.properties[yProperty];
        })
      );
      setTSel(
        selection.map((d) => {
          return d.properties.ST_NAME;
        })
      );
    } else {
      setXSel([]);
      setYSel([]);
      setTSel([]);
    }
  }, [geoData, callback, width, height, xProperty, yProperty, selection]);

  // Updates the selection key if selection changes
  useEffect(() => {
    const urlParams = selectionIndices.toString();

    if (
      selectKey.includes("|") &&
      urlParams !== selectKey.split("|")[1] &&
      !selectKey.includes("|mapgl=true")
    ) {
      const temp = selectKey
        .split("|")[1]
        .split(",")
        .map((index) => geoData[index]);

      callback(temp);
    }
  }, [selectKey]);

  return (
    <div>
      <Plot
        onSelected={(v) => {
          v.points.length ? setSelectKey(selectKey.split("|")[0] + "|" + v.points.map((s) => s.pointIndex).toString()) : "".join();
          v.points.length ? setSelectionIndices(v.points.map((s) => s.pointIndex)) : "".join();
          v.points.length ? setSelection(v) : "".join();
        }}
        data={[
          {
            type: "scattergl",
            mode: "markers",
            x: x,
            y: y,
            // marker: geoData.map(d => {return d.properties}),
            text: t,
            hovertemplate:
              "<b>%{text}</b><br><br>" +
              "%{yaxis.title.text}: %{y:.8f}<br>" +
              "%{xaxis.title.text}: %{x:.2f}<br>" +
              "<extra></extra>",
            marker: {
              line: {
                color: "var(--dark-default)",
                width: 0.8,
              },
            },
          },
          {
            type: "scattergl",
            mode: "markers",
            x: xSel,
            y: ySel,
            text: tSel,
          },
        ]}
        layout={{
          width: width,
          height: height,
          margin: {
            l: 60,
            r: 20,
            b: 40,
            t: 40,
            pad: 5,
          },
          yaxis: {
            type: yScale,
            title: yProperty.charAt(0).toUpperCase() + yProperty.slice(1),
          },
          xaxis: {
            title: xProperty.charAt(0).toUpperCase() + xProperty.slice(1),
          },
          showlegend: false,
          dragmode: "lasso",
          title: title,
        }}
      />
    </div>
  );
}
