import React from "react";
import HeroSection from "../components/home/herosection";
import Infobox from "../components/home/background";
import Documentation from "../components/home/documentation";

/*
Home Page

* Handles rendering of documentation
*/
function Home() {
  return (
    <div>
      <div className="section" id="home">
        <HeroSection />
      </div>
      <div className="section" id="infoBox">
        <Infobox />
      </div>
      <div className="section" id="documentation">
        <Documentation />
      </div>
    </div>
  );
}

export default Home;
