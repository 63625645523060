import { useEffect } from "react";
import "../../css/application.css";
import Slider from "@mui/material/Slider";
import Divider from "@mui/material/Divider";
import SplitButton from "../tools/splitButton";
import HoverPopover from "../tools/hoverPopup";

import { GET_FILTERS, HSPA_API_BASE } from "../../config/constants";
import { TIP_MESSAGES } from "../../config/tips";

/*
Cost Filters Component

* Manages state of current filter values
* Registers callback for benefits to filters
*/
export default function CostFilters({
  selectedData,
  filters,
  setFilters,
  setValues,
  shortenedData,
}) {

  // Get Filters from API when page first loading
  useEffect(() => {
    fetch(HSPA_API_BASE + GET_FILTERS)
      .then((response) => response.json())
      .then(
        (result) => {
          const costFilters = {};
          Object.keys(result).forEach((filter) => {
            if (result[filter].name.includes("Rating") || 
                result[filter].name.includes("Jobs") || 
                result[filter].name.includes("evacuation") || 
                result[filter].name.includes("Centrality")
            )
              costFilters[filter] = result[filter];
          });
          setFilters(costFilters);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  // Updates a specific property of the filters object
  const updateFilter = (filter, value) => {
    const newFilters = { ...filters };
    newFilters[filter].default = value;
    setFilters(newFilters);
  };

  // Creates a Slider Input
  function rangeInput(min, max, defaultValue, setValue) {
    const marks = [
      {
        value: min,
        label: min,
      },
      {
        value: max,
        label: max,
      },
    ];

    return (
      <Slider
        value={defaultValue}
        min={min}
        step={(max - min) / 20.0}
        max={max}
        marks={marks}
        valueLabelDisplay="auto"
        onChange={(e) => setValue(Number(e.target.value))}
        onChangeCommitted={setValues}
      />
    );
  }

  return (
    <div className="filters">
      <div className="colSide">
        <div className="submit">
          <SplitButton downloadData={shortenedData} fileName="Download " />
        </div>
        <ul>
          {Object.keys(filters).map((filterKey) => {
            const filter = filters[filterKey];
            let theFilter = <h1>{filter.name}</h1>;

            if (filter.type === "number") {
              theFilter = rangeInput(
                filter.min,
                filter.max,
                filter.default,
                (changeValue) => updateFilter(filterKey, changeValue)
              );
            } else if (filter.type === "header") {
              return (
                <li className="filterHeader" key={filterKey}>
                  <div className="divBar">
                    <Divider variant="middle" />
                  </div>
                  <h2 className="col">{filter.name}</h2>
                </li>
              );
            }

            return (
              <li className="filter" key={filterKey}>
                <div className="col">
                  <div className="popupRow">
                    {filter.name}: {TIP_MESSAGES[filterKey] ? <HoverPopover hoverInfo={TIP_MESSAGES[filterKey]} /> : null}
                  </div>
                  {theFilter}
                </div>
              </li>
            );
          })}
        </ul>
        <div className="space" />
      </div>
    </div>
  );
}
