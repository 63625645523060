import React from "react";
import "../../css/herosection.css";
import mapImg from "../../images/landing_v2.png";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

function HeroSection() {
  return (
    <div className="hero-background">
      <div className="hero-container">
        <h1>Hillside Street</h1>
        <p>Prioritization Application</p>
        <div className="description-box">
          <div className="textbox">
            This application shows prioritized streets in terms of capital
            improvement given user input data and boundaries
          </div>
          <div className="hero-btns">
            <Button variant="contained" component={Link} to="/application">
              START
            </Button>
          </div>
        </div>
      </div>
      <img
        src={mapImg}
        className="mapImage"
        alt="Map of LA"
        width="300"
        height="400"
      />
    </div>
  );
}

export default HeroSection;
