import { useEffect, useState } from "react";
import "../../css/application.css";
import Checkbox from "@mui/material/Checkbox";
import Slider from "@mui/material/Slider";
import Divider from "@mui/material/Divider";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SplitButton from "../tools/splitButton";
import MultiSelect from "../tools/multiSelect";
import HoverPopover from "../tools/hoverPopup";

import { GET_FILTERED_DATA, GET_FILTERS, HSPA_API_BASE } from "../../config/constants";

/*
Filters Component

* Manages state of current filter values
* Registers callback for clearing filters and filterKey
*/
export default function Filters({
  callBack,
  selectedData,
  setSelection,
  filterString,
  setFilterString,
}) {
  const [filters, setFilters] = useState({});
  const [rwAccordionFilters, setRWAccordionFilters] = useState(new Map());
  const [iwAccordionFilters, setIWAccordionFilters] = useState(new Map());
  const [rowrFilters, setROWRFilters] = useState(new Map());
  const [rowcFilters, setROWCFilters] = useState(new Map());
  const ratingWeightsFilters = ["Width Rating Weight", "PCI Rating Weight", "Curb Rating Weight", "Sidewalk Rating Weight"];
  const importanceWeightsFilters = ["Jobs of workers age 29 or younger", "Jobs for workers age 30 to 54", "Jobs for workers age 55 or older", "Jobs with earnings $1250/month or less", "Jobs with earnings $1251/month to $3333/month", "Jobs with earnings greater than $3333/month", "Jobs in goods producing industry sectors", "Jobs in trade, transportation, and utilities industry sectors", "Jobs in all other services industry sectors", "Centrality Distance", "Centrality Population", "Centrality Time", "Centrality Width", "Primary evacuation roads", "Secondary evacuation roads"];
  const rightOfWayRequiredFilters = ["RoW Min Required Width", "RoW Required Mean Width", "RoW Required Median Width", "RoW Required Maximum Width", "RoW Required Width Standard-Deviation"];
  const rightOfWayCurrentFilters = ["RoW Current Mininum Width", "RoW Current Mean Width", "RoW Current Median Width", "RoW Current Maximum Width"];

  // Get Filters from API when page first loading
  useEffect(() => {
    fetch(HSPA_API_BASE + GET_FILTERS)
      .then((response) => response.json())
      .then(
        (result) => {
          setFilters(result);
          const filterValues = {};
          Object.keys(result).forEach((filterKey) => {
            if ("default" in result[filterKey])
              filterValues[filterKey] = result[filterKey].default;
          });
          const urlParams = new URLSearchParams(filterValues);
          setFilterString(`${urlParams}`);
          executeCallback();
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  // Handles updating data when filter key is updated 
  useEffect(() => {
    // Reformats current filter values
    const filterValues = {};
    Object.keys(filters).forEach((filter) => {
      if ("default" in filters[filter])
        filterValues[filter] = filters[filter].default;
    });

    const urlParams = new URLSearchParams(filterValues);

    // Confirms discrepency between current filter values and filter key
    if (`${urlParams}` !== filterString.split("|")[0]) {
      // Clearing selection
      setSelection(null);

      // Storing selection data
      const selectionKey = filterString.split("|").length > 1 ? filterString.split("|")[1] : "";

      // Determining new filter values
      const newFilters = filters;
      for (const [key, value] of new URLSearchParams(
        filterString.split("|")[0]
      )) {
        if (newFilters[key].type === "range") {
          newFilters[key].default = value.split(",").map((x) => parseInt(x));
        } else if (newFilters[key].type === "check") {
          newFilters[key].default = parseInt(value);
        } else if (newFilters[key].type === "select") {
          newFilters[key].default = value.split(",");
        } else if ("default" in newFilters[key]) {
          newFilters[key].default = value;
        }
      }

      // Updating filters
      setFilters(newFilters);

      // Updating underlying data with new filters
      fetch(HSPA_API_BASE + GET_FILTERED_DATA + `?` + filterString.split("|")[0])
        .then((response) => response.json())
        .then((result) => {
          callBack(result);
        })
        .then(() => {
          // Updating filter string with selection
          setFilterString(filterString.split("|")[0] + "|" + selectionKey + "|filtered=true");
        })
        .catch((error) => {
          console.error("There was a problem fetching goeJson Data", error);
        });
    }
  }, [filterString]);

  // Updates a specific property of the filters object
  const updateFilter = (filter, value) => {
    const newFilters = { ...filters };
    newFilters[filter].default = value;
    setFilters(newFilters);
  };

  // Creates a slider input
  function rangeInput(min, max, defaultValue, setValue) {
    const marks = [
      {
        value: min,
        label: min,
      },
      {
        value: max,
        label: max,
      },
    ];

    return (
      <Slider
        value={defaultValue}
        min={min}
        step={(max - min) / 20.0}
        max={max}
        marks={marks}
        valueLabelDisplay="auto"
        onChange={(e) => setValue(Number(e.target.value))}
        onChangeCommitted={executeCallback}
      />
    );
  }

  // Create a dual input slider 
  function dualRangeInput(min, max, minLabel, maxLabel, defaultValue, setValue) {
    const marks = [
      {
        value: min,
        label: minLabel,
      },
      {
        value: max,
        label: maxLabel,
      },
    ];

    return (
      <Slider
        value={defaultValue}
        min={min}
        step={(max - min) / 20.0}
        max={max}
        marks={marks}
        valueLabelDisplay="auto"
        onChange={(e, newValue) => setValue(newValue)}
        onChangeCommitted={executeCallback}
      />
    );
  }

  // Creates a dropdown Input
  function selectInput(selection, defaultValue, setValue) {
    return (
      <MultiSelect
        options={Object.keys(selection)}
        setOptions={setValue}
        selectedOptions={defaultValue}
        callback={executeCallback}
      ></MultiSelect>
    );
  }

  // Creates a checkbox input
  function checkInput(val, setValue) {
    return (
      <Checkbox
        onChange={(e) => {
          setValue(e.target.checked ? 1 : 0);
          executeCallback();
        }}
        checked={val === 1}
      />
    );
  }

  // Function to handle updating of data upon filter change (and calling callback() parameter on completion)
  function executeCallback() {
    // Clear current selection
    setSelection(null);

    // Build parameter list from current filters
    const filterValues = {};
    Object.keys(filters).forEach((filterKey) => {
      if (filters[filterKey].type === "check") {
        filterValues[filterKey] = filters[filterKey].default;
      } else if ("default" in filters[filterKey])
        filterValues[filterKey] = filters[filterKey].default;
    });
    const urlParams = new URLSearchParams(filterValues);
    const filterStr = `${urlParams}`;
    if (filterStr.length > 1) {
      setFilterString(`${urlParams}`);
    }
    const startTime = performance.now();
    // Gets the filtered data from filters
    fetch(HSPA_API_BASE + GET_FILTERED_DATA + `?${urlParams}`)
      .then((response) => {
        
        const res = response.json()
        const endTime = performance.now();
        console.log("RUNTIME: ", endTime - startTime)
        return res
      })
      .then((result) => {
        // Call callback parameter function
        callBack(result);
      })
      .catch((error) => {
        console.error("There was a problem fetching geoJson Data", error);
      });
  }

  return (
    <div className="filters">
      <div className="colSide">
        <div className="submit">
          <SplitButton downloadData={selectedData} fileName="Download " />
        </div>
        <ul>
        {Object.keys(filters).map((filterKey) => {
            const filter = filters[filterKey];
            const theFilter = <h1>{filter.name}</h1>;

            // Check if filter belongs in RW Accordion
            if (ratingWeightsFilters.includes(filter.name)) {
              if (rwAccordionFilters.size !== ratingWeightsFilters.length) {
                const newRWAccordionFilters = rwAccordionFilters;
                newRWAccordionFilters.set(filter, [filterKey, filter]);
                setRWAccordionFilters(newRWAccordionFilters);
              }
            }

            // Check if filter belongs in IW Accordion
            if (importanceWeightsFilters.includes(filter.name)) {
              if (iwAccordionFilters.size !== importanceWeightsFilters.length) {
                const newIWAccordionFilters = iwAccordionFilters;
                newIWAccordionFilters.set(filter, [filterKey, theFilter]);
                setIWAccordionFilters(newIWAccordionFilters);
              }
            }

            // Check if filter belongs in RoW RW Accordion
            if (rightOfWayRequiredFilters.includes(filter.name)) {
              if (rowrFilters.size !== rightOfWayRequiredFilters.length) {
                const newROWRAccordionFilters = rowrFilters;
                newROWRAccordionFilters.set(filter, [filterKey, theFilter]);
                setROWRFilters(newROWRAccordionFilters);
              }
            }

            // Check if filter belongs in IW Accordion
            if (rightOfWayCurrentFilters.includes(filter.name)) {
              if (rowcFilters.size !== rightOfWayCurrentFilters.length) {
                const newROWCAccordionFilters = rowcFilters;
                newROWCAccordionFilters.set(filter, [filterKey, theFilter]);
                setROWCFilters(newROWCAccordionFilters);
              }
            }
          })}

          {Object.keys(filters).map((filterKey) => {
            const filter = filters[filterKey];
            let theFilter = <h1>{filter.name}</h1>;

            // Check if filter already is in Accordion
            if (ratingWeightsFilters.includes(filter.name) || importanceWeightsFilters.includes(filter.name) || rightOfWayRequiredFilters.includes(filter.name) || rightOfWayCurrentFilters.includes(filter.name)) {
              return;
            }

            if (filter.type === "number")
              theFilter = rangeInput(
                filter.min,
                filter.max,
                filter.default,
                (changeValue) => updateFilter(filterKey, changeValue)
              );
            else if (filter.type === "select") {
              theFilter = selectInput(
                filter.options,
                filter.default,
                (changeValue) => updateFilter(filterKey, changeValue)
              );
            } else if (filter.type === "check") {
              theFilter = checkInput(filter.default, (changeValue) =>
                updateFilter(filterKey, changeValue)
              );
            } else if (filter.type === "header") {
              if (filter.name === "Rating Weights") {
                return (
                  <Accordion key={filterKey} sx={{backgroundColor: 'transparent'}}>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <h2>{filter.name}</h2>
                    </AccordionSummary>
                    <AccordionDetails key={filterKey}>
                      {Array.from(rwAccordionFilters.entries()).map(([filter, filterInfo]) => {
                        const filterKey = filterInfo[0];
                        const theFilter = rangeInput(
                          filter.min,
                          filter.max,
                          filter.default,
                          (changeValue) => updateFilter(filterKey, changeValue)
                        );
                        return (
                        <li className="filter" key={filterKey}>
                          <div className="col">
                            <div className="popupRow">
                              {filter.name}: {filter.tooltip.length !== 0 ? <HoverPopover hoverInfo={filter.tooltip} /> : null}
                            </div>
                            {theFilter}
                          </div>
                        </li>
                        );
                      })}
                      
                    </AccordionDetails>
                  </Accordion>
                );
              } else if (filter.name === "Importance Weights") {
                return (
                  <Accordion key={filterKey} sx={{backgroundColor: 'transparent'}}>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <h2>{filter.name}</h2>
                    </AccordionSummary>
                    <AccordionDetails key={filterKey}>
                      {Array.from(iwAccordionFilters.entries()).map(([filter, filterInfo]) => {
                        const filterKey = filterInfo[0];
                        const theFilter = rangeInput(
                          filter.min,
                          filter.max,
                          filter.default,
                          (changeValue) => updateFilter(filterKey, changeValue)
                        );
                        return (
                        <li className="filter" key={filterKey}>
                          <div className="col">
                            <div className="popupRow">
                              {filter.name}: {filter.tooltip.length !== 0 ? <HoverPopover hoverInfo={filter.tooltip} /> : null}
                            </div>
                            {theFilter}
                          </div>
                        </li>
                        );
                      })}
                      
                    </AccordionDetails>
                  </Accordion>
                );
              } else if (filter.name === "Right of Way - Required Width") {
                return (
                  <Accordion key={filterKey} sx={{backgroundColor: 'transparent'}}>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <h2>{filter.name}</h2>
                    </AccordionSummary>
                    <AccordionDetails key={filterKey}>
                      {Array.from(rowrFilters.entries()).map(([filter, filterInfo]) => {
                        const filterKey = filterInfo[0];
                        const theFilter = dualRangeInput(
                          filter.min,
                          filter.max,
                          filter.minLabel ?? filter.min,
                          filter.maxLabel ?? filter.max,
                          filter.default,
                          (changeValue) => updateFilter(filterKey, changeValue)
                        );
                        return (
                        <li className="filter" key={filterKey}>
                          <div className="col">
                            <div className="popupRow">
                              {filter.name}: {filter.tooltip.length !== 0 ? <HoverPopover hoverInfo={filter.tooltip} /> : null}
                            </div>
                            {theFilter}
                          </div>
                        </li>
                        );
                      })}
                      
                    </AccordionDetails>
                  </Accordion>
                );
              } else if (filter.name === "Right of Way - Current Width") {
                return (
                  <Accordion key={filterKey} sx={{backgroundColor: 'transparent'}}>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <h2>{filter.name}</h2>
                    </AccordionSummary>
                    <AccordionDetails key={filterKey}>
                      {Array.from(rowcFilters.entries()).map(([filter, filterInfo]) => {
                        const filterKey = filterInfo[0];
                        const theFilter = dualRangeInput(
                          filter.min,
                          filter.max,
                          filter.minLabel ?? filter.min,
                          filter.maxLabel ?? filter.max,
                          filter.default,
                          (changeValue) => updateFilter(filterKey, changeValue)
                        );
                        return (
                        <li className="filter" key={filterKey}>
                          <div className="col">
                            <div className="popupRow">
                              {filter.name}: {filter.tooltip.length !== 0 ? <HoverPopover hoverInfo={filter.tooltip} /> : null}
                            </div>
                            {theFilter}
                          </div>
                        </li>
                        );
                      })}
                      
                    </AccordionDetails>
                  </Accordion>
                );
              } else {
                return (
                  <li className="filterHeader" key={filterKey}>
                    <div className="divBar">
                      <Divider variant="middle" />
                    </div>
                    <h2 className="col">{filter.name}</h2>
                  </li>
                );
              }
            } else if (filter.type === "range") {
              theFilter = dualRangeInput(
                filter.min,
                filter.max,
                filter.minLabel ?? filter.min,
                filter.maxLabel ?? filter.max,
                filter.default,
                (changeValue) => updateFilter(filterKey, changeValue)
              );
            }

            return (
              <li className="filter" key={filterKey}>
                <div className="col">
                  <div className="popupRow">
                    {filter.name}: {filter.tooltip.length !== 0 ? <HoverPopover hoverInfo={filter.tooltip} /> : null}
                  </div>
                  {theFilter}
                </div>
              </li>
            );
          })}
        </ul>
        <div className="space" />
      </div>
    </div>
  );
}
