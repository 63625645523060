import * as React from "react";
import { POTREE_BASE } from "../../config/constants";

/*
PoTree Component

* Provides access to potree visualizations stored in s3
* Provides sizing functionality
*/
export default function PoTree({ currLink, width, height }) {
  const awsDomain = POTREE_BASE;

  return (
    <div className="col">
      <iframe
        src={awsDomain + currLink + ".html"}
        width={width}
        height={height}
      ></iframe>
    </div>
  );
}
