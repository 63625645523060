import { useState, useEffect } from 'react';
import '../css/application.css';
import { Button, Box, TextField, Divider } from '@mui/material';
import Mapgl from '../components/mapboxEvacAccess/mapgl';
import SplitButton from '../components/tools/splitButton';

import {
    HSPA_API_BASE,
    GET_EVACUATION_DATA,
    GET_SHORTENED_DATA,
    NAVIGATE_LA_LINK,
    HSPA_TITLE,
} from '../config/constants';

/*
Evacuation Page

* Handles state of currently selected section ID
* Handles state of evacuation path data for selecte section ID
* Handles state of total road data
*/
function Evacuation({ dimensions }) {
    const [sectionID, setSectionID] = useState(3512700);
    const [geoData, setGeoData] = useState([]);
    const [path1, setPath1] = useState([]);
    const [path2, setPath2] = useState([]);
    const [sectionData, setData] = useState([]);

    // Gets latest evacuation data for the selected section ID
    useEffect(() => {
        fetch(HSPA_API_BASE + GET_EVACUATION_DATA + `?id=` + String(sectionID))
            .then((response) => response.json())
            .then(
                (result) => {
                    setPath1(result.path1Data);
                    setPath2(result.path2Data);
                    setData(result);
                },
                (error) => {
                    console.log(error);
                }
            )
            .catch((error) => {
                console.error('There was a problem fetching geoJson Data', error);
            });
    }, [sectionID]);

    // Gets shortened hillside data and saves it to render in the map
    useEffect(() => {
        fetch(HSPA_API_BASE + GET_SHORTENED_DATA)
            .then((response) => response.json())
            .then((res) => {
                setGeoData(res);
            })
            .catch((error) => {
                console.error('There was a problem fetching goeJson Data', error);
            });
    }, []);

    // Function to update road if clicked
    function roadClicked(roadData) {
        if (roadData.length > 0) {
            setSectionID(roadData[0].properties.SECT_ID);
        }
    }

    return (
        <div className="application">
            <Box sx={{ display: 'flex' }}>
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div className="intro">
                        <h1>{HSPA_TITLE} Evacuation</h1>
                    </div>
                    <div className="col">
                        <h2 className="navla">
                            Explore Section IDs:
                            <Button target="_blank" href={NAVIGATE_LA_LINK} variant="contained">
                                Navigate LA
                            </Button>
                        </h2>
                        <div className="row">
                            <div className="col">
                                <h2>Select Section ID:</h2>
                                <div className="sectionInput">
                                    <TextField
                                        type="number"
                                        value={sectionID}
                                        onChange={(e) => setSectionID(e.target.value)}
                                    ></TextField>
                                </div>
                                <div className="divBar">
                                    <Divider variant="middle" />
                                </div>
                                <div className="evacDataContainer">
                                    {sectionData.sectionID == null ? (
                                        <h2>Data Loading...</h2>
                                    ) : sectionData.Error == null ? (
                                        <div>
                                            <h2>Evacuation Data:</h2>
                                            <ul>
                                                <li className="evacData">
                                                    Section ID: {sectionData.sectionID}
                                                </li>
                                                <li className="evacData">
                                                    Street Name: {sectionData.streetName}
                                                </li>
                                                <li className="evacData">
                                                    Council District:{' '}
                                                    {parseInt(sectionData.district)}
                                                </li>
                                                <li className="evacData">
                                                    Primary Evacuation Time:{' '}
                                                    {Number.parseFloat(
                                                        sectionData.primaryEvacTime
                                                    ).toFixed(2)}
                                                    s
                                                </li>
                                                <li className="evacData">
                                                    Secondary Evacuation Time:{' '}
                                                    {Number.parseFloat(
                                                        sectionData.secondaryEvacTime
                                                    ).toFixed(2)}
                                                    s
                                                </li>
                                                <li className="evacData">
                                                    Path Overlap:{' '}
                                                    {Number.parseFloat(sectionData.overlap).toFixed(
                                                        2
                                                    )}
                                                    %
                                                </li>
                                            </ul>
                                            <div className="divBar">
                                                <Divider variant="middle" />
                                            </div>
                                            <h2>Download Paths:</h2>
                                            <div className="evacData">
                                                <SplitButton
                                                    downloadData={path1}
                                                    fileName="Path 1"
                                                />
                                            </div>
                                            <div className="evacData">
                                                <SplitButton
                                                    downloadData={path2}
                                                    fileName="Path 2"
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <h2>{sectionData.Error}</h2>
                                    )}
                                </div>
                            </div>
                            <div className="col">
                                <Mapgl
                                    geoData={[
                                        {
                                            data: geoData,
                                            color: ['white'],
                                            id: 'highlight',
                                            width: 2.5,
                                            displayWidth: 6,
                                        },
                                        { data: path1, color: ['blue'], id: 'path1', width: 4 },
                                        { data: path2, color: ['red'], id: 'path2', width: 4 },
                                    ]}
                                    width={(dimensions.width * 75) / 100}
                                    height={600}
                                    mapZoom={11}
                                    initLat={34.1017}
                                    initLng={-118.4438}
                                    polyControl={false}
                                    controls={['full']}
                                    callback={roadClicked}
                                />
                            </div>
                        </div>
                    </div>
                </Box>
            </Box>
        </div>
    );
}

export default Evacuation;
