import './App.css';
import React, { useEffect, useState } from 'react';
import Navbar from './components/template/navbar';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from './pages/home';
import Application from './pages/application';
import Evacuation from './pages/evacuation';
import Accessibility from './pages/accessibility';
import Collection from './pages/collection';
import CostAnalysis from './pages/costAnalysis';
import Footer from './components/template/footer';
import ImageUpload from './pages/imageInspection';

// Debouncing function: will only call fn once per ever ms milliseconds
function debounce(fn, ms) {
    let timer;
    return (_) => {
        clearTimeout(timer);
        timer = setTimeout((_) => {
            timer = null;
            fn.apply(this, arguments);
        }, ms);
    };
}
/*
Render App Content

* Manages Routing
* Manages Current window dimensions
*/
function App() {
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    const [selection, setSelection] = useState(null);
    const [file, setFile] = useState(null);

    useEffect(() => {
        console.log("File " + file)
    },[file])

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        }, 1000);

        window.addEventListener('resize', debouncedHandleResize);

        return (_) => {
            window.removeEventListener('resize', debouncedHandleResize);
        };
    });
    console.log(dimensions);

    return (
        <>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <Navbar />
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route
                        path="/application"
                        element={
                            <Application setSelection={setSelection} dimensions={dimensions} setFile={setFile}/>
                        }
                    />
                    <Route path="/imageInspection" element = {<ImageUpload file={file}/>}></Route>
                    <Route path="/evacuation" element={<Evacuation dimensions={dimensions} />} />
                    <Route path="/accessibility" element={<Accessibility dimensions={dimensions} />} />
                    <Route path="/collection" element={<Collection dimensions={dimensions} />} />
                    <Route
                        path="/costAnalysis"
                        element={
                            <CostAnalysis defaultSelection={selection} dimensions={dimensions} />
                        }
                    />
                </Routes>
                <Footer />
            </BrowserRouter>
        </>
    );
}

export default App;
