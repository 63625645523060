import Map, { NavigationControl, Source, Layer, Popup, FullscreenControl } from 'react-map-gl';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button } from '@mui/material';
import axois from 'axios';
import DrawControl, { drawRef } from './draw-control';

import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import '../../css/mapgl.css';

import { MAPBOX_TOKEN } from '../../config/constants';

// Function to clear polygons using draw control reference
export function clearMap() {
    if (drawRef != null) {
        drawRef.deleteAll();
    }
}

/*
Mapbox Component

* Provides functionality to view customizable geojson data on map
* Provides optional hover functionality
* Provides interface for custom controls
*/
export default function Mapgl({
    geoData,
    width,
    height,
    mapZoom,
    initLat,
    initLng,
    setFeatures,
    controls,
    lng,
    lat,
    callback,
}) {
    const [streets, setStreets] = useState(null);
    const [hoverInfo, setHoverInfo] = useState(null);

    // Sets mapbox reference for jump to functionality
    const mapRef = useRef();

    // Function to open google maps in new tab based on current location
    async function findNearestStreetView(curLat, curLon) {
        const url =
            'https://api.opencagedata.com/geocode/v1/json?q=' +
            curLat +
            '+' +
            curLon +
            '&key=1a22bb5c0e6c4092ad8028aacd80740e';
        try {
            const response = await axois.get(url);

            const { results } = response.data;
            if (results && results.length > 0) {
                const { geometry } = results[0];
                window.open(
                    'http://maps.google.com/maps?q=&layer=c&cbll=' +
                        geometry.lat +
                        ',' +
                        geometry.lng
                );
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    // Jump to functionality if input lat or lng parameters changed
    useEffect(() => {
        // console.log('useEffect is running');
        if (mapRef != null && mapRef.current != null) {
            // console.log(mapRef,"jumping to", initLng, initLat);
            mapRef.current.jumpTo({ center: [initLng, initLat] });
        }
    }, [initLng, initLat]);

    // Cleaning input data, then adding features to mapbox plot. Triggered when input data is modified
    useEffect(() => {
        if (geoData[0].data != null) {
            // Cleaning data (narrowing down to essential fields)
            geoData.forEach((group) => {
                try {
                    const output = [];
                    group.data.forEach((data) => {
                        const row = {};
                        row.type = data.type;
                        row.geometry = data.geometry;
                        row.properties = {};
                        if (group.id.includes('cost')) {
                            row.properties.curb_rating = data.properties.curb_rating;
                            row.properties.width_rating = data.properties.width_rating;
                            row.properties.sidewalk_rating = data.properties.sidewalk_rating;
                            row.properties.pci_rating = data.properties.pci_rating;
                        } else {
                            row.properties.condition = data.properties.condition;
                            row.properties.importance = data.properties.importance;
                            row.properties.hazard = data.properties.hazard;
                        }
                        row.properties.ST_NAME = data.properties?.ST_NAME
                            ? data.properties.ST_NAME
                            : data.properties?.disp_name
                            ? data.properties.disp_name
                            : data.properties.NAME;
                        row.properties.ST_WIDTH = data.properties.ST_WIDTH;
                        row.properties.SECT_ID = data.properties.SECT_ID
                            ? data.properties.SECT_ID
                            : data.properties.ObjectID;

                        output.push(row);
                    });

                    group.data = output;
                } catch (error) {}
            });

            // Setting data
            setStreets(
                geoData.map((group) => (
                    <Source
                        id={group.id}
                        type="geojson"
                        data={{
                            type: 'FeatureCollection',
                            features:
                                group.color.length <= 1
                                    ? group.data
                                    : group.data.map((geom, index) => ({
                                          type: geom.type,
                                          geometry: geom.geometry,
                                          properties: {
                                              ...geom.properties,
                                              color: group.color[index],
                                          },
                                      })),
                        }}
                        key={group.id}
                    >
                        <Layer
                            id={group.displayWidth ? group.id + '_display' : group.id}
                            type="line"
                            layout={{
                                'line-join': 'round',
                                'line-cap': 'round',
                            }}
                            paint={{
                                'line-color':
                                    group.color.length <= 1 ? group.color[0] : ['get', 'color'],
                                'line-width': group.width,
                            }}
                            beforeId={group.before}
                        />
                        {group.displayWidth ? (
                            <Layer
                                id={group.id}
                                type="line"
                                layout={{
                                    'line-join': 'round',
                                    'line-cap': 'round',
                                }}
                                paint={{
                                    'line-color': group.color[0],
                                    'line-width': group.displayWidth,
                                    'line-opacity': 0.01,
                                }}
                                beforeId={group.before}
                            />
                        ) : null}
                    </Source>
                ))
            );
        }
        // If no data, then empty map
        else {
            setHoverInfo(null);
            setStreets(null);
        }
    }, [geoData]);

    // Functionality to add hover popup if layer id includes "highlight"
    const onHover = useCallback((event) => {
        const { features, lngLat } = event;

        // Filter features to extract valid street segment and Sect ID
        const highlightFeature = features.find(
            (feature) => feature.layer.id === "highlight"
        );

        if (
            features.length > 0 &&
            hoverInfo === null &&
            !features[0].layer.id.includes('highlight') &&
            highlightFeature != null
        ) {
            setHoverInfo(
                <Popup
                    anchor="top"
                    longitude={Number(lngLat.lng)}
                    latitude={Number(lngLat.lat)}
                    onClose={() => setHoverInfo(null)}
                    focusAfterOpen={false}
                >
                    <div>
                        <p>
                            <strong>{highlightFeature.properties.ST_NAME}</strong>
                        </p>
                        {highlightFeature.properties.SECT_ID != null ? (
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://navigatela.lacity.org/navigatela/?search=ssi%20${highlightFeature.properties.SECT_ID}`}
                            >
                                Sect ID: {highlightFeature.properties.SECT_ID}
                            </a>
                        ) : null}
                    </div>

                    <Button
                        variant="contained"
                        onClick={() => findNearestStreetView(lngLat.lat, lngLat.lng)}
                    >
                        Street View
                    </Button>
                </Popup>
            );
        }
    }, []);

    // Function to process clicked road
    const onClick = useCallback((event) => {
        // eslint-disable-next-line no-unused-vars
        const { features, lngLat } = event;
        callback(features);
    }, []);

    // Function to handle update to polygons
    const onUpdate = useCallback((e) => {
        setFeatures((currFeatures) => {
            const newFeatures = { ...currFeatures };
            for (const f of e.features) {
                newFeatures[f.id] = f;
            }
            return newFeatures;
        });
    }, []);

    // Function to handle deletion of polygons
    const onDelete = useCallback((e) => {
        setFeatures((currFeatures) => {
            const newFeatures = { ...currFeatures };
            for (const f of e.features) {
                delete newFeatures[f.id];
            }
            return newFeatures;
        });
    }, []);

    return (
        <Map
            ref={mapRef}
            mapboxAccessToken={MAPBOX_TOKEN}
            initialViewState={{
                longitude: initLng,
                latitude: initLat,
                zoom: mapZoom,
            }}
            style={{ width, height }}
            mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
            interactiveLayerIds={geoData.map((x) => x.id)}
            onMouseMove={onHover}
            onMouseDown={onClick}
        >
            {streets}
            {hoverInfo}
            {controls.some((control) => control === 'draw') ? (
                <DrawControl
                    position="top-left"
                    displayControlsDefault={false}
                    controls={{
                        polygon: true,
                        trash: true,
                    }}
                    onCreate={onUpdate}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                    clear={onUpdate}
                />
            ) : null}
            {controls.some((control) => control === 'full') ? <FullscreenControl /> : null}
            <NavigationControl />
        </Map>
    );
}
