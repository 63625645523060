import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { Checkbox } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
// import { useRef } from "react";
// import { useState, useEffect } from "react";

// MUI table custom styling 
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#353740",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

// Table column breakdown
const costAnalysisColumns = [
  {
    id: "SECT_ID",
    label: "SECT_ID",
    scenario: 0,
  },
  {
    id: "ST_NAME",
    label: "ST_NAME",
    scenario: 0,
  },
  {
    id: "ST_LENGTH",
    label: "ST_LENGTH",
    scenario: 0
  },
  {
    id: "importance",
    label: "Importance",
    aggregate: {
      "centrality_distance": "widthRW",
      "centrality_time": "pciRW",
      "centrality_population": "curbRW",
      "centrality_width": "sidewalkRW"
    },
    scenario: 0,
  },
  {
    id: "width_rating",
    label: "Width Rating",
    scenario: 0,
  },
  {
    id: "curb_rating",
    label: "Curb Rating",
    scenario: 0,
  },
  {
    id: "sidewalk_rating",
    label: "Sidewalk Rating",
    scenario: 0
  },
  {
    id: "pci_rating",
    label: "PCI",
    scenario: 0
  },
  {
    id: "cost_curb",
    label: "Repair Curb",
    minWidth: 140,
    scenario: 1,
  },
  {
    id: "cost_widening",
    label: "Widen",
    minWidth: 140,
    scenario: 1,
  },
  {
    id: "cost_sidewalk",
    label: "Add Sidewalk",
    minWidth: 140,
    scenario: 1,
  },
  {
    id: "cost_widening_20",
    label: "Widen To 20",
    minWidth: 140,
    scenario: 1,
  },
  {
    id: "cost_widening_24",
    label: "Widen To 24",
    minWidth: 140,
    scenario: 1,
  },
  {
    id: "cost_resurface",
    label: "Resurface",
    minWidth: 140,
    scenario: 1,
  },
];

/*
Cost Table Component

* Provides pagination feature for table data
* Provides control over table granularity
* Provides functionality to select various scenarios, and remove rows from table
* Sets parameter benefits and costs based on parameter state
*/
export default function StickyHeadTable({
  geoData,
  width,
  deleteRow,
  benefits,
  costs,
  setCost,
  setBenefit,
  updateValues,
  updateSection,
  currSection,
  autoData,
  calculateTotalBenfits
}) {

  var isChecked = false;
  // const [isChecked, setIsChecked] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const [analyzedData, setAnalyzedData] = useState([]);
  // const checkedRef = useRef(false);

  // Function to address page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Function to handle change in page granularity
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  // useEffect(() => {
  //   setAnalyzedData(autoData);
  // })

  // const handleCheckboxChange = (isChecked, column, row, benefit, value) => {
  //   // Your existing logic for handling checked and unchecked state
  //   if (isChecked) {

  //       // Set cost
  //       const newCosts = costs;
  //       if (row.SECT_ID in costs){
  //         newCosts[row.SECT_ID] = [...costs[row.SECT_ID], value]
  //       } else{
  //         newCosts[row.SECT_ID] = [value]
  //       }
  //       setCost(newCosts);

  //       // Set benefit
  //       const newBenefits = benefits;
  //       if (column.id in benefits) {
  //         newBenefits[column.id] = [...benefits[column.id], [row.SECT_ID, benefit]];
  //       } else {
  //         newBenefits[column.id] = [[row.SECT_ID, benefit]];
  //       }
  //       setBenefit(newBenefits);

  //     // ... your existing code for handling checked state

  //   } else {

  //     const newCosts = costs;
  //     if (row.SECT_ID in costs){
  //       newCosts[row.SECT_ID] = costs[row.SECT_ID].filter((e) => e !== value)
  //       setCost(newCosts);
  //     }

  //     // Remove benefit
  //     if (column.id in benefits) {
  //       const newBenefits = benefits;
  //       newBenefits[column.id] = benefits[column.id].filter((e) => e[1] !== benefit);
  //       setBenefit(newBenefits);
  //     }

  //     // ... your existing code for handling unchecked state
  //   }
  //   updateValues();
  // };


  return geoData == null ? (
    <div></div>
  ) : (
    <Paper sx={{ width: width, overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell key={"_delete"} />
              {costAnalysisColumns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {geoData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                // console.log(index);
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.SECT_ID}
                    sx={{ backgroundColor: currSection === row.SECT_ID ? "#c3c6d6" : "#ffffff" }}
                  >
                    <StyledTableCell key={"_delete"}>
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          deleteRow(row.SECT_ID);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          updateSection(row.SECT_ID);
                        }}
                      >
                        <NotListedLocationIcon />
                      </IconButton>
                    </StyledTableCell>
                    {costAnalysisColumns.map((column) => {

                      const value = row[column.id];
                      // console.log(autoData[index]);
                      // var isChecked = false;
                      // let benefit = 0;

                      // if (column.id.includes("Curb")) {
                      //   benefit = row.curb;
                      // } else if (column.id.includes("Widen")) {
                      //   benefit = row.width;
                      // }

                      if (
                        column.id === "cost_curb" ||
                        column.id === "cost_widening" ||
                        column.id === "cost_sidewalk" ||
                        column.id === "cost_resurface" ||
                        column.id === "cost_widening_20" ||
                        column.id === "cost_widening_24"
                      ) {





                        isChecked = autoData[index]?.[column.id].checked;



                        // if (isChecked) {

                        //   // Set cost
                        //   const newCosts = costs;
                        //   if (row.SECT_ID in costs){
                        //     newCosts[row.SECT_ID] = [...costs[row.SECT_ID], value]
                        //   } else{
                        //     newCosts[row.SECT_ID] = [value]
                        //   }
                        //   setCost(newCosts);

                        //   // Set benefit
                        //   const newBenefits = benefits;
                        //   if (column.id in benefits) {
                        //     newBenefits[column.id] = [...benefits[column.id], [row.SECT_ID, benefit]];
                        //   } else {
                        //     newBenefits[column.id] = [[row.SECT_ID, benefit]];
                        //   }
                        //   setBenefit(newBenefits);
                        // } 

                        // else {
                        //   // console.log("got here!");
                        //   // Remove cost
                        //   const newCosts = costs;
                        //   if (row.SECT_ID in costs){
                        //     newCosts[row.SECT_ID] = costs[row.SECT_ID].filter((e) => e !== value)
                        //     setCost(newCosts);
                        //   }

                        //   // Remove benefit
                        //   if (column.id in benefits) {
                        //     const newBenefits = benefits;
                        //     newBenefits[column.id] = benefits[column.id].filter((e) => e[1] !== benefit);
                        //     setBenefit(newBenefits);
                        //   }
                        // }

                        // console.log(isChecked);

                        // checkedRef.current = isChecked;
                        // if(isChecked) {
                        //   // handleCheckboxChange(isChecked, column, row, benefit, autoData[index]?.[column.id].value)
                        // }


                        // console.log(autoDataForIndex?.[column.id]?.checked);
                        // const actionData = autoData[row.SECT_ID] || {};
                        // console.log("Action Data " + Object.keys(actionData));
                        // const action = actionData[column.id] || {};
                        // console.log("action " + Object.keys(action));

                        // Use the checked value from autoData
                        // const isChecked = action.checked || false;

                      }



                      return (
                        <StyledTableCell key={column.id} align={column.align}>
                          {(() => {
                            if (column.id.includes("cost")) {
                              return Math.trunc(value);
                            } else if(column.id !== "SECT_ID" && typeof value === "number") { 
                              return value.toFixed(6);
                            }
                            else {
                              return value;
                            }
                  
                          })()}
                          {column.scenario ? (
                            <Checkbox
                              checked={!!isChecked}


                              // checked = {isChecked}
                              // checked = {column.id === "Repair_Curb" ||
                              // column.id === "Widen" ||
                              // column.id === "Add_Sidewalk" ||
                              // column.id === "Repave"
                              //   ? (autoData[row.SECT_ID]?.[column.id]?.checked || false)
                              //   : false}
                              onChange={(e) => {

                                // isChecked = !isChecked;

                                const item = autoData[index]?.[column.id];

                                if (item) {
                                  item.checked = !item.checked;
                                }

                                calculateTotalBenfits();

                                // console.log(item.checked);

                                // if (item.checked) {

                                //   // Set cost
                                //   const newCosts = costs;
                                //   if (row.SECT_ID in costs){
                                //     newCosts[row.SECT_ID] = [...costs[row.SECT_ID], value]
                                //   } else{
                                //     newCosts[row.SECT_ID] = [value]
                                //   }
                                //   setCost(newCosts);

                                //   // Set benefit
                                //   const newBenefits = benefits;
                                //   if (column.id in benefits) {
                                //     newBenefits[column.id] = [...benefits[column.id], [row.SECT_ID, benefit]];
                                //   } else {
                                //     newBenefits[column.id] = [[row.SECT_ID, benefit]];
                                //   }
                                //   setBenefit(newBenefits);
                                // } 


                                // else {
                                //   // console.log("got here!");
                                //   // Remove cost
                                //   const newCosts = costs;
                                //   if (row.SECT_ID in costs){
                                //     newCosts[row.SECT_ID] = costs[row.SECT_ID].filter((e) => e !== value)
                                //     setCost(newCosts);
                                //   }

                                //   // Remove benefit
                                //   if (column.id in benefits) {
                                //     const newBenefits = benefits;
                                //     newBenefits[column.id] = benefits[column.id].filter((e) => e[1] !== benefit);
                                //     setBenefit(newBenefits);
                                //   }
                                // }
                                // updateValues();
                              }}
                            />
                          ) : null}
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 50, 200]}
        component="div"
        count={geoData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
