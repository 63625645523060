import { useState, useEffect } from 'react';
import '../css/application.css';
import Filters from '../components/filters/filters';
import ScatterPlot from '../components/plots/scatterPlot';
import ScatterPlot3D from '../components/plots/scatterPlot3D';
import JsonTable from '../components/plots/jsonTable';
import { Drawer, Box, TextField, Button } from '@mui/material';
import Mapgl, { clearMap } from '../components/mapboxApp/mapgl';
import * as turf from '@turf/turf';
import { HashLink } from 'react-router-hash-link';
import HoverPopover from '../components/tools/hoverPopup';

import { HSPA_TITLE, NAVIGATE_LA_LINK } from '../config/constants';

/*
Application Page

* Handles state of currently full street data
* Handles state of filter key
* Handles updating selection upon map polygon changes
* Handles updating selection upon polygon in scatter plots
*/
function Application({ setSelection, dimensions, setCurrentSection, setFile}) {
    // const [currentSection, setCurrentSection] = useState(null);
    const [geoData, setGeoData] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [mapPolygons, setPolygons] = useState({});
    const [filterKey, setFilterKey] = useState('');
    const [selectedIndices, setIndices] = useState([]);
    const [sectionID, setSectionID] = useState(3512700);
    const [latVal, setLatVal] = useState(34.069);
    const [lngVal, setLngVal] = useState(-118.4438);



    // useEffect(() => {
    //     console.log("Current Section: " + currentSection)
    // }, [currentSection])
    // Wrapper function for settingGeoData
    function updateGeoData(geoDataNew) {
        setGeoData(geoDataNew);
        // console.log(geoDataNew);
        console.log(
            geoDataNew
                .map((street) => street.properties.Shape__Length)
                .reduce((partialSum, a) => partialSum + a, 0) / 5280
        );
    }

    // Wrapper function for setting selected data - handles clearing of previous data
    function updateGeoSelection(geoSelection) {
        clearMap();
        setPolygons({});
        if (geoSelection != null) {
            setSelection(geoSelection);
            console.log(
                geoSelection
                    .map((street) => street.properties.Shape__Length)
                    .reduce((partialSum, a) => partialSum + a, 0) / 5280
            );
        }

        console.log('Geoselection: ' + geoSelection);
        setSelectedData(geoSelection);
    }

    // Handles refreshing selection after polygon drawn on map
    useEffect(() => {
        console.log(mapPolygons[0]);
        if (Object.keys(mapPolygons).length !== 0) {
            const containedData = [];
            const indices = [];
            Object.values(mapPolygons).forEach((p) => {
                geoData.forEach((sel, index) => {
                    if (
                        sel.geometry.coordinates.length > 0 &&
                        sel.geometry.coordinates[0].length === 2 &&
                        !isNaN(sel.geometry.coordinates[0][0]) &&
                        !isNaN(sel.geometry.coordinates[0][1]) &&
                        turf.booleanPointInPolygon(turf.point(sel.geometry.coordinates[0]), p)
                    ) {
                        containedData.push(sel);
                        indices.push(index);
                    }
                });
            });

            setIndices(indices);
            setFilterKey(filterKey.split('|')[0] + '|' + indices.toString());
            setSelectedData(containedData);
            setSelection(containedData);
        }
    }, [mapPolygons]);

    // Handles manually entering section ID to select road
    useEffect(() => {
        // fetchDataBasedOnSectionID(sectionID);
        if (sectionID.length === 7) {
            console.log('changed section ID to:', sectionID);
            geoData.forEach((sel, index) => {
                if (sel.properties.SECT_ID === sectionID) {
                    // console.log('yay', sel);
                    setSelectedData([sel]);
                    setLatVal(sel.geometry.coordinates[0][1]);
                    setLngVal(sel.geometry.coordinates[0][0]);
                    // console.log(sel.geometry.coordinates[0][0], sel.geometry.coordinates[0][1],lngVal,latVal);
                    // map.setCenter([sel.geometry.coordinates[0][0], sel.geometry.coordinates[0][1]]);
                }
                // console.log(sel.properties.SECT_ID);
            });
        }
    }, [sectionID]);

    return (
        <div className="application">
            <Box sx={{ display: 'flex' }}>
                <Drawer
                    variant="permanent"
                    sx={{
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { boxSizing: 'border-box' },
                        zIndex: 900,
                        width: '20vw',
                    }}
                >
                    <div className="sideBar">
                        <Box sx={{ overflow: 'auto', paddingTop: '100px' }}>
                            <Filters
                                callBack={updateGeoData}
                                selectedData={selectedData}
                                setSelection={updateGeoSelection}
                                setFilterString={setFilterKey}
                                filterString={filterKey}
                            />
                        </Box>
                    </div>
                </Drawer>
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div className="intro">
                        <h1>{HSPA_TITLE} Prioritization</h1>
                        <Button
                            component={HashLink}
                            disabled={selectedData === null}
                            className="cost-analysis"
                            to="/costAnalysis"
                            variant="contained"
                        >
                            Cost Analysis
                        </Button>
                    </div>

                    <h2 className="costAnalysisHeader">
                        <div className="costAnalysisText">
                            Select Section ID:
                            <TextField
                                type="number"
                                value={sectionID}
                                onChange={(e) => setSectionID(e.target.value)}
                            />
                            {/* <Button variant="contained" onClick={addSection}>
                  Add Section ID
                </Button> */}
                        </div>
                        <div className="costAnalysisText">
                            Explore Section IDs:
                            <Button target="_blank" href={NAVIGATE_LA_LINK} variant="contained">
                                Navigate LA
                            </Button>
                        </div>
                    </h2>

                    <div className="col">
                        <div className="row">
                            <div className="twoCol">
                                <ScatterPlot
                                    geoData={geoData}
                                    callback={updateGeoSelection}
                                    width={(dimensions.width * 35) / 100}
                                    height={500}
                                    yScale="log"
                                    xProperty="condition"
                                    yProperty="importance"
                                    title="Importance vs. Condition"
                                    selection={selectedData}
                                    selectKey={filterKey}
                                    setSelectKey={setFilterKey}
                                    selectionIndices={selectedIndices}
                                    setSelectionIndices={setIndices}
                                />
                            </div>
                            <Mapgl
                                geoData={[
                                    { data: selectedData, color: 'red', id: 'street', width: 4 },
                                ]}
                                width={(dimensions.width * 35) / 100}
                                height={500}
                                mapZoom={13}
                                // initLat={34.069}
                                // initLng={-118.4438}
                                initLat={latVal}
                                initLng={lngVal}
                                setFeatures={setPolygons}
                                currentFeat={mapPolygons}
                                setCurrentSection = {setCurrentSection}
                                controls={['draw']}
                                callback={() => {}}
                                setFile={setFile}
                            />
                        </div>
                        <div className="row" />
                        <div className="row">
                            <div className="twoCol">
                                <ScatterPlot
                                    geoData={geoData}
                                    callback={updateGeoSelection}
                                    width={(dimensions.width * 35) / 100}
                                    height={500}
                                    yScale="linear"
                                    xProperty="condition"
                                    yProperty="hazard"
                                    title="Hazard vs. Condition"
                                    selection={selectedData}
                                    selectKey={filterKey}
                                    setSelectKey={setFilterKey}
                                    setSelectionIndices={setIndices}
                                    selectionIndices={selectedIndices}
                                />
                            </div>
                            <ScatterPlot3D
                                geoData={geoData}
                                width={(dimensions.width * 35) / 100}
                                height={500}
                                yScale="log"
                                xProperty="condition"
                                yProperty="importance"
                                zProperty="hazard"
                                title="Hazard vs. Condition vs Importance"
                                selection={selectedData}
                            />
                        </div>
                        <div className="row">
                            <JsonTable
                                geoData={selectedData}
                                headers="app"
                                width="70vw"
                            ></JsonTable>
                        </div>
                        <div className="row">
                            <h2 className="textBox">
                                Section Count: {geoData ? geoData.length : 0}
                            </h2>
                            <h2 className="textBox">
                                Selection Section Count: {selectedData ? selectedData.length : 0}
                            </h2>
                        </div>
                        <div className="row">
                            <h2 className="textBox">
                                Miles:{' '}
                                {geoData
                                    ? (
                                          geoData
                                              .map((street) => street.properties.Shape__Length)
                                              .reduce((partialSum, a) => partialSum + a, 0) / 5280
                                      ).toFixed(2)
                                    : 0.0}
                            </h2>
                            <h2 className="textBox">
                                Selected Miles:{' '}
                                {selectedData
                                    ? (
                                          selectedData
                                              .map((street) => street.properties.Shape__Length)
                                              .reduce((partialSum, a) => partialSum + a, 0) / 5280
                                      ).toFixed(2)
                                    : 0.0}
                            </h2>
                        </div>
                        <div className="row">
                            <div className="keyRow">
                                <HoverPopover hoverInfo="Filter Key" />
                                <TextField
                                    id="filter_key"
                                    label="Filter Key"
                                    value={filterKey}
                                    onChange={(e) => {
                                        setFilterKey(e.target.value);
                                    }}
                                    fullWidth
                                />
                            </div>
                        </div>
                    </div>
                </Box>
            </Box>
        </div>
    );
}

export default Application;
