import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "../../css/application.css";

/*
Hover Popup Component

* Provides customizable icon for hover and displays info when moused over
*/
export default function HoverPopover({ hoverInfo, tipWidth = 400 }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  // Function to show popup at target
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to remove popup at target
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className="popup">
      <InfoOutlinedIcon
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1, width: tipWidth, whiteSpace: 'normal', textAlign: 'left' }}>
          {hoverInfo}
        </Typography>
      </Popover>
    </div>
  );
}
