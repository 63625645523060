import { useState, useEffect } from "react";
import "../css/application.css";
import { Button, Box, TextField, Divider, Drawer } from "@mui/material";
import Mapgl from "../components/mapboxApp/mapgl";
import CostTable from "../components/plots/costTable";
import CostFilters from "../components/filters/costfilters";

import { HSPA_TITLE, GET_ALL_COST_DATA, NAVIGATE_LA_LINK, HSPA_API_BASE, GET_FILTERED_DATA } from "../config/constants";
// import { filter } from "d3";

/*
Cost Analysis Page

* Handles state of currently selected section ID and corresponding geometry
* Handles state of current map selection of section IDs and cost data
* Handles logic for updating total cost and benefit
*/
function CostAnalysis({ defaultSelection, dimensions }) {
  const [sectionID, setSectionID] = useState(3512700);
  const [currHighlightData, setHighlight] = useState([]);
  const [geoData, setGeoData] = useState([]);
  const [selectGeoData, setSelectGeoData] = useState([]);
  const [sectionData, setData] = useState([]);
  const [lat, setLat] = useState(34.1017);
  const [lng, setLng] = useState(-118.4438);
  const [budget, setBudget] = useState(0);
  const [automatedData, setAutomatedData] = useState([]);

  const [benefitValues, setBenefitValues] = useState({});
  const [filters, setFilters] = useState({});
  const [shortenedData, setShortenedData] = useState([]);

  const [totalCost, setTotalCost] = useState(0);
  const [totalBenefit, setTotalBenefit] = useState(0);
  const [totalImpBenefit, setTotalImpBenefit] = useState(0);

  // Function to remove a section ID from selection
  function removeSection(sectID) {

    sectionData.forEach((row) => {
      if (row.SECT_ID === sectID) {
        console.log(row)
      }
    });

    const newAutomatedList = automatedData.filter((row) => row.SECT_ID !== sectID);

    setAutomatedData(newAutomatedList);
    setData((sectionData.filter((row) => row.SECT_ID !== sectID)));
    console.log(newAutomatedList);
    // Remove costs
    // const updateCost = totalCost;
    // updateCost[sectID] = [];
    // setTotalCost(updateCost);

    // // Remove benefits
    // const updateBenefit = benefitValues;
    // Object.keys(updateBenefit).forEach((key) => {
    //   updateBenefit[key] = updateBenefit[key].filter((benefit) => benefit[0] !== sectID);
    // });
    // setBenefitValues(updateBenefit);
    // scaleAndSetBenefit();

    // Remove sectionID
    // setAutomatedData(automatedData.filter((row) => row.SECT_ID !== sectID));

    console.log(automatedData);
    setSelectGeoData(
      selectGeoData.filter(
        (row) => parseInt(row.properties.SECT_ID) !== parseInt(sectID)
      )
    );
  }

  useEffect(() => {

    console.log(sectionData);

    setData(addImportance(defaultSelection));

  }, []);

  useEffect(() => {

    console.log(sectionData);

    // setData(addImportance(defaultSelection));

  }, [sectionData]);
  // Function to set total benefit. This aggregates the benefits and scales by the respective weights
  function scaleAndSetBenefit() {
    let benefit = 0;
    let impBenefit = 0;

    Object.keys(benefitValues).forEach((col) => {
      if (benefitValues[col].length > 0) {
        const impUnscaled = (benefitValues[col].map((benefit) => (1 - benefit[1]) * sectionData.find((row) => row.SECT_ID === benefit[0]).importance).reduce((pv, cv) => pv + cv, 0));
        const unscaled = (benefitValues[col].length - benefitValues[col].reduce((pv, cv) => pv + cv[1], 0));

        if (col.includes("Curb")) {
          benefit += filters.curbRW.default * unscaled;
          impBenefit += filters.curbRW.default * impUnscaled
        } else if (col.includes("Widen")) {
          benefit += filters.widthRW.default * unscaled;
          impBenefit += filters.widthRW.default * impUnscaled
        }

      }
    });

    setTotalBenefit(benefit);
    setTotalImpBenefit(impBenefit);
  }


  // Function to add a new section to cost data (from text box)
  function addSection() {

    fetch(HSPA_API_BASE + GET_ALL_COST_DATA)
      .then((response) => {
        return response.json()
      })
      .then(
        (result) => {
          console.log(result[0]);
          for (let i = 0; i < result.length; i++) {
            const currentObject = result[i];

            if (parseInt(currentObject.properties.SECT_ID) === parseInt(sectionID)) {
              console.log(currentObject);
              if (sectionData.find(obj => obj.SECT_ID === sectionID)) {
                return;
              }
              else {
                console.log("got here!")
                const newList = [...sectionData];

                newList.push(currentObject.properties);

                console.log(newList);


                setData(newList);
                console.log(sectionData);
              }
            }
          }

          // if (
          //   sectionData.filter(
          //     (row) => parseInt(row.SECT_ID) === parseInt(sectionID)
          //   ).length === 0
          // ) {
          //   const currRoad = geoData.find(
          //     (o) => parseInt(o.properties.SECT_ID) === parseInt(sectionID)
          //   );
          //   if (currRoad !== undefined) {
          //     setSelectGeoData([...selectGeoData, currRoad]);
          //   }

          //   const newList = [...sectionData, ...result];

          //   console.log(newList);
          //   addImportance(newList);
          //   setData(newList);
          // }
        },
        (error) => {
          console.log(error);
        }
      )
      .catch((error) => {
        console.error("There was a problem fetching geoJson Data", error);
      });

  }


  useEffect(() => {
    calculateBenefits();
    // setData(automatedData);
  }, [automatedData]);



  useEffect(() => {
    console.log(shortenedData);
    // setData(automatedData);
  }, [shortenedData]);


  // Function to get total shortened data AND inital cost data (called on initial page loading)
  useEffect(() => {

    // Grab shortened total hillside road data
    fetch(HSPA_API_BASE + GET_FILTERED_DATA)
      .then(async (response) => await response.json())
      .then((res) => {
        console.log("RESULTANT: " + res);
        setGeoData(res);
        setSelectGeoData(defaultSelection == null ? [] : defaultSelection);
      })
      .catch((error) => {
        console.error("There was a problem fetching geoJson Data", error);
      });

    // Grab cost data for initial section selection
    fetch(HSPA_API_BASE + GET_ALL_COST_DATA)
      .then(async (response) => await response.json())
      .then(
        (result) => {

          console.log(result[0]);

          const validIDs = defaultSelection.map((row) =>
            parseInt(row.properties.SECT_ID)
          );



          const filteredData = [];
          console.log(result[0]);
          result.forEach((feature) => {
            console.log("Feature: " + feature);

            const featureProperty = feature.properties;


            if (validIDs.includes(parseInt(featureProperty.SECT_ID))) {
              filteredData.push(featureProperty);
            }


          });

          // addImportance(filteredData);

          // console.log(filteredData);
          setData(addImportance(filteredData));

          // console.log(filteredData);

          automate();

          // console.log("Automatic data " + automatedData);
        },
        (error) => {
          console.log(error);
        }
      )
      .catch((error) => {
        console.error("There was a problem fetching geoJson Data", error);
      });




  }, []);


  useEffect(() => {
    console.log("Section Data: " + sectionData);
    console.log(sectionData);
    automate();
    setData(addImportance(sectionData));
    calculateBenefits();
  }, [sectionData])
  // Function to update the section ID textbox if road clicked
  function roadClicked(roadData) {
    if (roadData.length > 0) {
      setSectionID(roadData[0].properties.SECT_ID);
    }
  }

  // Function to add importance to cost data
  function addImportance(roadData) {

    if (!roadData) {
      return roadData;
    }

    // const defaultImportance = 0.25;
    // Mapping of importance column to relevant weight


    const columnMapping = {
      "SA01W": "SA01_adjusted",
      "SA02W": "SA02_adjusted",
      "SA03W": "SA03_adjusted",
      "SE01W": "SE01_adjusted",
      "SE02W": "SE02_adjusted",
      "SE03W": "SE03_adjusted",
      "SI01W": "SI01_adjusted",
      "SI02W": "SI02_adjusted",
      "SI03W": "SI03_adjusted",
      "centrality_distance": "centrality_distance", 
      "centrality_population": "centrality_population", 
      "centrality_time": "centrality_time", 
      "centrality_width": "centrality_width", 
      "evac_importance_1": "evac_importance_path_1",
      "evac_importance_2": "evac_importance_path_2"
    }

    if (Object.keys(filters).length > 0) {



      roadData.forEach((row) => {
        let importance = 0;
        let weights = 0;


        Object.keys(columnMapping).map((id) => {

          const currentWeight = parseFloat(filters[id].default);
          // console.log(filters[id].default);
          weights += currentWeight;
          const currentRow = parseFloat(row[columnMapping[id]]);
          const i = currentRow * currentWeight;

          importance += i;
          //   row.importance = (Object.keys(columnMapping).map((id) => filters[columnMapping[id]].default * row[id])).reduce((a, b) => a + b, 0);
          // console.log();
        });

        row.importance = importance / weights;
      });
    }


    else {
      roadData.forEach(row => {
        row.importance = 0;
      });
    }

    return roadData;
  }

  const createNewListWithCheckedProperty = (originalList) => {
    var benefitRatioList = []
    // Iterate through the original list
    const newList = originalList.map((item) => {
      // For each action property, add a 'checked' property initialized to false
      const newItem = {};

      const cost_for_20 = item.cost_widening_20;
      const cost_for_24 = item.cost_widening_24;
      const cost_for_full = item.cost_widening;


      let benefit_for_20 = 0.4713 - item.width_rating;
      if (benefit_for_20 < 0) {
        benefit_for_20 = 0;
      }
      let benefit_for_24 = 0.7467 - item.width_rating;
      if (benefit_for_24 < 0) {
        benefit_for_24 = 0;
      }

      const benefit_for_full = 1 - item.width_rating;

      const weighted_benefit_20 = benefit_for_20 * filters.widthRW.default * item.importance;
      const weighted_benefit_24 = benefit_for_24 * filters.widthRW.default * item.importance;
      const weighted_benefit_full = benefit_for_full * filters.widthRW.default * item.importance;



      let cost_benefit_20 = 0
      if (cost_for_20 > 0) {
        cost_benefit_20 = weighted_benefit_20 / cost_for_20;
      }

      let cost_benefit_24 = 0
      if (cost_for_24 > 0) {
        cost_benefit_24 = weighted_benefit_24 / cost_for_24;
      }

      const cost_benefit_for_full = weighted_benefit_full / cost_for_full;

      const ratios = [
        { type: "20", ratio: cost_benefit_20, cost: cost_for_20, benefit: benefit_for_20 },
        { type: "24", ratio: cost_benefit_24, cost: cost_for_24, benefit: benefit_for_24 },
        { type: "full", ratio: cost_benefit_for_full, cost: cost_for_full, benefit: benefit_for_full }
      ];

      // Sort the ratios in descending order based on ratio value
      ratios.sort((a, b) => b.ratio - a.ratio);

      console.log(ratios);

      const highestRatio = ratios[0];

      benefitRatioList.push({ cost: highestRatio.cost, ratio: highestRatio.ratio, benefit: highestRatio.benefit });

      newItem.cost_widening = { value: cost_for_full, checked: false, costBenefitRatio: cost_benefit_for_full, benefit: benefit_for_full, weightedBenefit: weighted_benefit_full };
      newItem.cost_widening_20 = { value: cost_for_20, checked: false, costBenefitRatio: cost_benefit_20, benefit: benefit_for_20, weightedBenefit: weighted_benefit_20 };
      newItem.cost_widening_24 = { value: cost_for_24, checked: false, costBenefitRatio: cost_benefit_24, benefit: benefit_for_24, weightedBenefit: weighted_benefit_24 };






      Object.keys(item).forEach((key) => {
        if (key === "cost_curb" || key === "cost_widening" || key === "cost_resurface" || key === "cost_sidewalk" || key === "cost_widening_20" || key === "cost_widening_24") {
          var actionBenefit = 0;
          var benefitWeighted = 0;
          var itemImportance = item.importance;

          if (key === "cost_resurface") {
            actionBenefit = 1 - item.pci_rating;
            benefitWeighted = actionBenefit * filters.pciRW.default * itemImportance;

          }
          if (key === "cost_widening_20") {
            return;
            // actionBenefit = 0.264 - item.width_rating;
            // if(actionBenefit < 0) {
            //   actionBenefit = 0;
            // }
            // benefitWeighted = actionBenefit * filters.widthRW.default;
          }
          if (key === "cost_widening_24") {
            return;
            // actionBenefit = 0.5592 - item.width_rating;
            // if(actionBenefit < 0) {
            //   actionBenefit = 0;
            // }
            // benefitWeighted = actionBenefit * filters.widthRW.default;
          }

          if (key === "cost_widening") {
            return;
            // actionBenefit = 1 - item.width_rating;
            // benefitWeighted = actionBenefit * filters.widthRW.default * itemImportance;
          }


          if (key === "cost_sidewalk") {
            actionBenefit = 1 - item.sidewalk_rating;
            benefitWeighted = actionBenefit * filters.sidewalkRW.default * itemImportance;
            

          }

          if (key === "cost_curb") {
            actionBenefit = 1 - item.curb_rating;
            benefitWeighted = actionBenefit * filters.curbRW.default * itemImportance;

          }


          const actionCost = item[key];
          // Calculate the cost-to-benefit ratio for the specific action
          const costToBenefitRatio = benefitWeighted > 0 ? benefitWeighted / actionCost : 0;
          if (costToBenefitRatio > 0 && actionCost > 0) {
            benefitRatioList.push({ cost: actionCost, ratio: costToBenefitRatio, benefit: actionBenefit });

          }

          newItem[key] = { value: actionCost, checked: false, costBenefitRatio: costToBenefitRatio, benefit: actionBenefit, weightedBenefit: benefitWeighted };
        } else {
          newItem[key] = item[key];
        }


      });

      return newItem;
    });



    benefitRatioList.sort((a, b) => b.ratio - a.ratio);
    console.log(benefitRatioList);

    var budgetedList = [];
    var currentBudget = 0;

    
    benefitRatioList.forEach((data) => {
      if (data.cost > 0) {
        if (data.cost + currentBudget <= budget) {
          budgetedList.push(data);
          currentBudget += data.cost;
        }
      }

    })

    console.log("list " + budgetedList);



    budgetedList.forEach((data1) => {
      newList.forEach((data2) => {
        Object.keys(data2).forEach((key) => {
          if (key === "cost_resurface" || key === "cost_widening" || key === "cost_curb" || key === "cost_sidewalk" || key === "cost_widening_20" || key === "cost_widening_24") {
            if (data2[key].costBenefitRatio === data1.ratio && data2[key].value === data1.cost) {
              data2[key].checked = true;
            }
          }
        });
      });
    });

    return newList;
  }




  function calculateBenefits() {

    let b = 0;
    let i = 0;
    let c = 0;

    const newList = automatedData;
    newList.forEach((item) => {

      let check24 = true;
      let check20 = true;
      if (item.cost_widening.checked) {
        b += filters.widthRW.default * item.cost_widening.benefit;
        i += filters.widthRW.default * (item.cost_widening.benefit) * item.importance;
        c += item.cost_widening.value;
        check24 = false;
        check20 = false;
      }

      if (item.cost_widening_24.checked && check24) {
        b += filters.widthRW.default * item.cost_widening_24.benefit;
        i += filters.widthRW.default * (item.cost_widening_24.benefit) * item.importance;
        c += item.cost_widening_24.value;
        check20 = false;
      }

      if (item.cost_widening_20.checked && check20) {
        b += filters.widthRW.default * item.cost_widening_20.benefit;
        i += filters.widthRW.default * (item.cost_widening_20.benefit) * item.importance;
        c += item.cost_widening_20.value;

      }
      // eslint-disable-next-line no-unused-vars

      // Check if the current item has action properties

      // Iterate through action pro perties
      Object.keys(item).forEach((key) => {
        if (key === "cost_resurface" || key === "cost_sidewalk" || key === "cost_curb") {
          const action = item[key];


          // Apply scaling factors based on action type
          if (item[key].checked) {




            c += item[key].value;
            if (key === "cost_curb") {
              b += filters.curbRW.default * action.benefit;
              i += filters.curbRW.default * (action.benefit) * item.importance;
            }

            // else if (key === "cost_widening") {

          

            // }

            else if (key === "cost_sidewalk") {
              b += filters.sidewalkRW.default * action.benefit;

              i += filters.sidewalkRW.default * (action.benefit) * item.importance;
            }

            else if (key === "cost_resurface") {
              b += filters.pciRW.default * action.benefit;
              i += filters.pciRW.default * (action.benefit) * item.importance;


            }

            // else if (key === "cost_widening_20") {


            // }

            // else if (key === "cost_widening_24") {


            // }

          }
        }
      });







    });

    setTotalCost(c);
    setTotalBenefit(b);
    setTotalImpBenefit(i);

    getOnlyImportantData();
  }

  function getOnlyImportantData() {
    const costAnalysisColumns = [
      {
        id: "SECT_ID",
        label: "SECT_ID",
        scenario: 0,
      },
      {
        id: "ST_NAME",
        label: "ST_NAME",
        scenario: 0,
      },
      {
        id: "ST_LENGTH",
        label: "ST_LENGTH",
        scenario: 0
      },
      {
        id: "importance",
        label: "Importance",
        aggregate: {
          "centrality_distance": "widthRW",
          "centrality_time": "pciRW",
          "centrality_population": "curbRW",
          "centrality_width": "sidewalkRW"
        },
        scenario: 0,
      },
      {
        id: "width_rating",
        label: "Width Rating",
        scenario: 0,
      },
      {
        id: "curb_rating",
        label: "Curb Rating",
        scenario: 0,
      },
      {
        id: "sidewalk_rating",
        label: "Sidewalk Rating",
        scenario: 0
      },
      {
        id: "pci_rating",
        label: "PCI",
        scenario: 0
      },
      {
        id: "cost_curb",
        label: "Repair Curb",
        minWidth: 140,
        scenario: 1,
      },
      {
        id: "cost_widening",
        label: "Widen",
        minWidth: 140,
        scenario: 1,
      },
      {
        id: "cost_widening_20",
        label: "Widen To 20",
        minWidth: 140,
        scenario: 1,
      },
      {
        id: "cost_widening_24",
        label: "Widen To 24",
        minWidth: 140,
        scenario: 1,
      },
      {
        id: "cost_sidewalk",
        label: "Add Sidewalk",
        minWidth: 140,
        scenario: 1,
      },
      {
        id: "cost_resurface",
        label: "Resurface",
        minWidth: 140,
        scenario: 1,
      },
    ];

    if (sectionData !== null && automatedData !== null) {
      const filteredData = sectionData.map(row => filterColumns(row, costAnalysisColumns.map(column => column.id)));

      automatedData.forEach((row, index) => {
        
        filteredData[index].improve_curb = row.cost_curb.checked;
        filteredData[index].improve_sidewalk = row.cost_sidewalk.checked;
        filteredData[index].widen_road = row.cost_widening.checked;
        filteredData[index].resurface_road = row.cost_resurface.checked;
        filteredData[index].widen_to_20 = row.cost_widening_20.checked;
        filteredData[index].widen_to_24 = row.cost_widening_24.checked;

        // filteredData[index].cost_curb_checked = automatedData.cost_curb.checked;
        // filteredData[index].cost_widening_checked = automatedData.cost_widening.checked;
        // filteredData[index].cost_resurface_checked = automatedData.cost_resurface.checked;
        // filteredData[index].cost_sidewalk_checked = automatedData.cost_sidewalk.checked;

      })
      setShortenedData(filteredData);
    }






  }

  function filterColumns(row, columnIds) {
    const filteredRow = {};
    columnIds.forEach((columnId) => {
      filteredRow[columnId] = row[columnId];
    });
    return filteredRow;
  }



  function automate() {
    const newList = createNewListWithCheckedProperty(sectionData);
    // newList.forEach((data) => {
    //   console.log(data);
    // });

    setAutomatedData(newList);


    calculateBenefits();

    getOnlyImportantData();




  }
  const automateListWithoutImportance = (originalList) => {
    console.log(originalList);
    var benefitRatioList = []
    // Iterate through the original list
    const newList = originalList.map((item) => {
      // For each action property, add a 'checked' property initialized to false
      const newItem = {};

      Object.keys(item).forEach((key) => {
        if (key === "cost_curb" || key === "cost_widening" || key === "cost_resurface" || key === "cost_sidewalk" || key === "cost_widening_20" || key === "cost_widening_24") {
          var actionBenefit = 0;
          var benefitWeighted = 0;
          if (key === "cost_widening") {
            actionBenefit = 1 - item.width_rating;
            benefitWeighted = actionBenefit * filters.widthRW.default;
          }
          if (key === "cost_widening_20") {
            actionBenefit = 0.4713 - item.width_rating;
            if (actionBenefit < 0) {
              actionBenefit = 0;
            }
            benefitWeighted = actionBenefit * filters.widthRW.default;
          }
          if (key === "cost_widening_24") {
            actionBenefit = 0.7467 - item.width_rating;
            if (actionBenefit < 0) {
              actionBenefit = 0;
            }
            benefitWeighted = actionBenefit * filters.widthRW.default;
          }


          if (key === "cost_resurface") {
            actionBenefit = 1 - item.pci_rating;
            benefitWeighted = actionBenefit * filters.pciRW.default;

          }

          if (key === "cost_sidewalk") {
            actionBenefit = 1 - item.sidewalk_rating;
            benefitWeighted = actionBenefit * filters.sidewalkRW.default;

          }

          if (key === "cost_curb") {
            actionBenefit = 1 - item.curb_rating;
            benefitWeighted = actionBenefit * filters.curbRW.default;

          }


          const actionCost = item[key];
          // Calculate the cost-to-benefit ratio for the specific action
          const costToBenefitRatio = benefitWeighted > 0 ? benefitWeighted / actionCost : 0;
          if (costToBenefitRatio > 0) {
            benefitRatioList.push({ cost: actionCost, ratio: costToBenefitRatio, benefit: actionBenefit });

          }


          newItem[key] = { value: actionCost, checked: false, costBenefitRatio: costToBenefitRatio, benefit: actionBenefit, weightedBenefit: benefitWeighted };
        } else {
          newItem[key] = item[key];
        }


      });

      return newItem;
    });



    benefitRatioList.sort((a, b) => b.ratio - a.ratio);

    var budgetedList = [];
    var currentBudget = 0;
    benefitRatioList.forEach((data) => {
      if (data.cost > 0) {
        if (data.cost + currentBudget <= budget) {
          budgetedList.push(data);
          currentBudget += data.cost;
        }
      }

    })

    console.log(budgetedList);



    budgetedList.forEach((data1) => {
      newList.forEach((data2) => {
        Object.keys(data2).forEach((key) => {
          if (key === "cost_resurface" || key === "cost_widening" || key === "cost_curb" || key === "cost_sidewalk" || key === "cost_widening_20" || key === "cost_widening_24") {
            if (data2[key].costBenefitRatio === data1.ratio) {
              data2[key].checked = true;
            }
          }
        });
      });
    });

    return newList;
  }

  function automateWithoutImportance() {
    const newList = automateListWithoutImportance(sectionData);



    setAutomatedData(newList);

    calculateBenefits();
  }

  useEffect(() => {
    calculateBenefits();
  }, [sectionData])

  useEffect(() => {
    setData(addImportance(sectionData));
    const roadData = addImportance(automatedData);

    setAutomatedData(roadData);

    calculateBenefits();
  }, [filters]);

  useEffect(() => {
    getOnlyImportantData();
  }, [automatedData])



  // Function to update currently highlighted road
  useEffect(() => {
    const currRoad = geoData.find(
      (o) => parseInt(o.properties.SECT_ID) === parseInt(sectionID)
    );
    setHighlight(currRoad ? [currRoad] : []);
    if (currRoad) {
      setLng(currRoad.geometry.coordinates[0][0]);
      setLat(currRoad.geometry.coordinates[0][1]);
    }

    automate();
  }, [sectionID, sectionData]);


  return (
    <div className="application">
      <Box sx={{ display: "flex" }}>
        <Drawer
          variant="permanent"
          sx={{
            [`& .MuiDrawer-paper`]: {
              boxSizing: "border-box",
              backgroundColor: "var(--background-primary)",
            },
            zIndex: 900,
            width: "20vw",
          }}
        >
          <div className="sideBar">
            <Box sx={{ overflow: "auto", paddingTop: "100px" }}>
              <CostFilters
                selectedData={sectionData}
                filters={filters}
                setFilters={setFilters}
                setValues={calculateBenefits}
                shortenedData={shortenedData}
              />
            </Box>
          </div>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <div className="intro">
            <h1>{HSPA_TITLE} Cost Analysis</h1>
          </div>
          <div className="col">
            <h2 className="costAnalysisHeader">
              <div className="costAnalysisText">
                Select Section ID:
                <TextField
                  type="number"
                  value={sectionID}
                  onChange={(e) => setSectionID(e.target.value)}
                />
                <Button variant="contained" onClick={addSection}>
                  Add Section ID
                </Button>
              </div>
              <div className="costAnalysisText">
                Explore Section IDs:
                <Button
                  target="_blank"
                  href={NAVIGATE_LA_LINK}
                  variant="contained"
                >
                  Navigate LA
                </Button>
              </div>
            </h2>
            <div className="row">
              <div className="col">
                <Mapgl
                  geoData={[
                    {
                      data: geoData,
                      color: "white",
                      id: "streetcost",
                      width: 2,
                    },
                    {
                      data: selectGeoData,
                      color: "lime",
                      id: "path1cost",
                      width: 4,
                    },
                    {
                      data: currHighlightData,
                      color: "blue",
                      id: "path2cost",
                      width: 4
                    }
                  ]}
                  width={(dimensions.width * 70) / 100}
                  height={500}
                  mapZoom={11}
                  initLat={34.1017}
                  initLng={-118.4438}
                  lat={lat}
                  lng={lng}
                  polyControl={false}
                  controls={["full"]}
                  callback={roadClicked}
                />
              </div>
            </div>
            <div className="divBar">
              <Divider variant="middle" />
            </div>
            <div className="row">
              <div className="col">
                <CostTable
                  geoData={sectionData}
                  width="70vw"
                  benefits={benefitValues}
                  costs={totalCost}
                  setBenefit={setBenefitValues}
                  setCost={setTotalCost}
                  deleteRow={removeSection}
                  calculateTotalBenfits={calculateBenefits}
                  updateValues={scaleAndSetBenefit}
                  updateSection={setSectionID}
                  currSection={sectionID}
                  weights={filters}
                  autoData={automatedData}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h2 className="textBox">
                  Total Benefit: {totalBenefit.toPrecision(4)}
                </h2>
                <br />
                <h2 className="textBox">
                  Total Benefit (Importance): {totalImpBenefit.toPrecision(4)}
                </h2>
                <h2 className="textBox">
                  Budget:
                  <input
                    type="number"
                    value={budget}
                    onChange={(e) => setBudget(parseFloat(e.target.value))}
                  />

                  <button onClick={automate}>Automate</button>
                  <button onClick={automateWithoutImportance}>No Importance Automate</button>

                </h2>
              </div>
              <h2 className="textBoxTall">
                Total Cost: {totalCost.toPrecision(8)}
              </h2>
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
}

export default CostAnalysis;
