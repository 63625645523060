import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { useControl } from "react-map-gl";

// Export a reference to the mapbox javascript object - allows for more granular operations
export let drawRef = null;

/*
Draw Mapbox Control

* Provides functionality to draw and remove polygons with mapbox
* Registers callback functions to polygon creation, updates, and deletion
*/
export default function DrawControl(props) {
  drawRef = useControl(
    () => new MapboxDraw(props),
    ({ map }) => {
      map.on("draw.create", props.onCreate);
      map.on("draw.update", props.onUpdate);
      map.on("draw.delete", props.onDelete);
    },
    ({ map }) => {
      map.off("draw.create", props.onCreate);
      map.off("draw.update", props.onUpdate);
      map.off("draw.delete", props.onDelete);
    },
    {
      position: props.position,
    }
  );

  return null;
}
