import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";

// Custom table styling
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#353740",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

// Application page columns
const applicationColumns = [
  {
    id: "OBJECTID",
    label: "OBJECTID",
  },
  {
    id: "SECT_ID",
    label: "SECT_ID",
  },
  // {
  //   id: "ST_DIR",
  //   label: "ST_DIR",
  // },
  {
    id: "ST_NAME",
    label: "ST_NAME",
  },
  // {
  //   id: "ST_FROM",
  //   label: "ST_FROM",
  // },
  // {
  //   id: "ST_TO",
  //   label: "ST_TO",
  // },
  {
    id: "ST_TYPE",
    label: "ST_TYPE",
  },
  {
    id: "ST_SURFACE",
    label: "ST_SURFACE",
  },
  // {
  //   id: "SSORT2",
  //   label: "SSORT2",
  // },
  // {
  //   id: "SSORT1",
  //   label: "SSORT1",
  // },
  // {
  //   id: "LANE_MILES",
  //   label: "LANE_MILES",
  // },
  // {
  //   id: "CL_MILES",
  //   label: "CL_MILES",
  // },
  {
    id: "CD",
    label: "CD",
  },
  {
    id: "ST_LENGTH",
    label: "ST_LENGTH",
  },
  {
    id: "ST_WIDTH",
    label: "ST_WIDTH",
  },
  // {
  //   id: "Shape__Length",
  //   label: "Shape__Length",
  // },
  // {
  //   id: "source",
  //   label: "source",
  // },
  {
    id: "pci",
    label: "pci",
  },
  // {
  //   id: "cond_status",
  //   label: "cond_status",
  // },
  {
    id: "Street_Designation",
    label: "Street_Designation",
  },
  // {
  //   id: "Curbs",
  //   label: "Curbs",
  // },
  // {
  //   id: "Sidewalks",
  //   label: "Sidewalks",
  // },
  {
    id: "required_width",
    label: "required_width",
  },
  // {
  //   id: "width_ratio",
  //   label: "width_ratio",
  // },
  // {
  //   id: "sidewalk_ratio",
  //   label: "sidewalk_ratio",
  // },
  // {
  //   id: "curb_ratio",
  //   label: "curb_ratio",
  // },
  // {
  //   id: "rating",
  //   label: "rating",
  // },
  // {
  //   id: "centrality_distance",
  //   label: "centrality_distance",
  // },
  // {
  //   id: "centrality_population",
  //   label: "centrality_population",
  // },
  // {
  //   id: "centrality_time",
  //   label: "centrality_time",
  // },
  // {
  //   id: "centrality_width",
  //   label: "centrality_width",
  // },
  // {
  //   id: "centroid_lat",
  //   label: "centroid_lat",
  // },
  // {
  //   id: "centroid_lon",
  //   label: "centroid_lon",
  // },
  {
    id: "DAC",
    label: "DAC",
  },
  {
    id: "BufferYN",
    label: "BufferYN",
  },
  {
    id: "lowincome",
    label: "lowincome",
  },
  // {
  //   id: "scagfldzn",
  //   label: "scagfldzn",
  // },
  // {
  //   id: "scagsubty",
  //   label: "scagsubty",
  // },
  // {
  //   id: "scaganualhazardflood",
  //   label: "scaganualhazardflood",
  // },
  // {
  //   id: "Burn_Prob",
  //   label: "Burn_Prob",
  // },
  // {
  //   id: "mean_left_side",
  //   label: "mean_left_side",
  // },
  // {
  //   id: "std_left_side",
  //   label: "std_left_side",
  // },
  // {
  //   id: "min_left_side",
  //   label: "min_left_side",
  // },
  // {
  //   id: "max_left_side",
  //   label: "max_left_side",
  // },
  // {
  //   id: "mean_right_side",
  //   label: "mean_right_side",
  // },
  // {
  //   id: "std_right_side",
  //   label: "std_right_side",
  // },
  // {
  //   id: "min_right_side",
  //   label: "min_right_side",
  // },
  // {
  //   id: "max_right_side",
  //   label: "max_right_side",
  // },
  // {
  //   id: "street_slope_per_length",
  //   label: "street_slope_per_length",
  // },
  // {
  //   id: "street_slope_absolute",
  //   label: "street_slope_absolute",
  // },
  {
    id: "currently_withdrawn",
    label: "currently_withdrawn",
  },
  {
    id: "prev_withdrawn",
    label: "prev_withdrawn",
  },
  {
    id: "prev_withdrawn_unmaintained",
    label: "prev_withdrawn_unmaintained",
  },
  {
    id: "unmaintained_add",
    label: "unmaintained_add",
  },
  {
    id: "prev_withdrawn_maintained",
    label: "prev_withdrawn_maintained",
  },
  {
    id: "maintained",
    label: "maintained",
  },
  {
    id: "landslide_zone",
    label: "landslide_zone",
  },
  {
    id: "evac_status",
    label: "evac_status",
  },
  {
    id: "evac_path1_len",
    label: "evac_path1_len",
  },
  {
    id: "evac_path2_len",
    label: "evac_path2_len",
  },
  {
    id: "evac_pathinter_len",
    label: "evac_pathinter_len",
  },
  // {
  //   id: "insp_status",
  //   label: "insp_status",
  // },
  // {
  //   id: "insp_paved",
  //   label: "insp_paved",
  // },
  // {
  //   id: "insp_curb",
  //   label: "insp_curb",
  // },
  // {
  //   id: "insp_sidewalk",
  //   label: "insp_sidewalk",
  // },
  // {
  //   id: "insp_slope",
  //   label: "insp_slope",
  // },
  // {
  //   id: "insp_parking_cars",
  //   label: "insp_parking_cars",
  // },
  // {
  //   id: "insp_hydrant",
  //   label: "insp_hydrant",
  // },
  // {
  //   id: "insp_guard_rail",
  //   label: "insp_guard_rail",
  // },
  // {
  //   id: "insp_trees",
  //   label: "insp_trees",
  // },
  // {
  //   id: "insp_trash",
  //   label: "insp_trash",
  // },
  // {
  //   id: "insp_road_width",
  //   label: "insp_road_width",
  // },
  // {
  //   id: "insp_curb_height",
  //   label: "insp_curb_height",
  // },
  // {
  //   id: "insp_sidewalk_width",
  //   label: "insp_sidewalk_width",
  // },
  // {
  //   id: "insp_slope_angle",
  //   label: "insp_slope_angle",
  // },
  // {
  //   id: "evac_overlap",
  //   label: "evac_overlap",
  // },
  {
    id: "hazard_up_landslide",
    label: "hazard_up_landslide",
  },
  {
    id: "hazard_down_landslide",
    label: "hazard_down_landslide",
  },
  // {
  //   id: "hazard_burn",
  //   label: "hazard_burn",
  // },
  {
    id: "width_rating",
    label: "width_rating",
  },
  {
    id: "sidewalk_rating",
    label: "sidewalk_rating",
  },
  {
    id: "pci_rating",
    label: "pci_rating",
  },
  {
    id: "curb_rating",
    label: "curb_rating",
  },
  {
    id: "importance",
    label: "importance",
  },
  {
    id: "hazard",
    label: "hazard",
  },
  {
    id: "condition",
    label: "condition",
  },
];

// Collection page columns
const collectionColumns = [
  {
    id: "# Sect_Id",
    label: "#_Sect_Id",
  },
  {
    id: "Curb_Height",
    label: "Curb_Height",
  },
  {
    id: "Curb_Presence",
    label: "Curb_Presence",
  },
  {
    id: "End_Image",
    label: "End_Image",
  },
  {
    id: "Fire_Hydrants",
    label: "Fire_Hydrants",
  },
  {
    id: "Guard_Rail",
    label: "Guard_Rail",
  },
  {
    id: "Parking_Cars",
    label: "Parking_Cars",
  },
  {
    id: "Road_Surface",
    label: "Road_Surface",
  },
  {
    id: "Road_Width",
    label: "Road_Width",
  },
  {
    id: "Sidewalk_Presence",
    label: "Sidewalk_Presence",
  },
  {
    id: "Sidewalk_Width",
    label: "Sidewalk_Width",
  },
  {
    id: "Slope_Angle",
    label: "Slope_Angle",
  },
  {
    id: "Slope_Presence",
    label: "Slope_Presence",
  },
  {
    id: "Start_Image",
    label: "Start_Image",
  },
  {
    id: "Street_Trees",
    label: "Street_Trees",
  },
  {
    id: "Trash",
    label: "Trash",
  },
  {
    id: "length",
    label: "length",
  },
];

/*
Json Table Component

* Provides pagination feature for table data
* Provides control over table granularity
*/
export default function StickyHeadTable({ geoData, headers, width }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Function to address page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Function to handle change in page granularity
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Determining which columns to use
  const columns = headers === "collection" ? collectionColumns : applicationColumns;

  return geoData == null ? (
    <div></div>
  ) : (
    <Paper sx={{ width: width, overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {geoData
              .map((p) => p.properties)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.OBJECTID}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <StyledTableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 50, 200]}
        component="div"
        count={geoData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
