import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import "../../css/navbar.css";

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <HashLink to="/" className="navbar-logo" onClick={closeMobileMenu}>
            HSPA
          </HashLink>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <HashLink
                smooth
                to="/#home"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Home
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                smooth
                to="/application"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Application
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                smooth
                to="/evacuation"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Evacuation
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                smooth
                to="/accessibility"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Accessibility
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                smooth
                to="/collection"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Collection
              </HashLink>
            </li>

            <li className="nav-item">
              <HashLink
                smooth
                to="/imageInspection"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Inspection
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                smooth
                to="/#documentation"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Documentation
              </HashLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
// export default Navbar = ({barStyle}) => {
//     const checkbarStyle = STYLES.includes(barStyle) ? barStyle : STYLES[0];
//     return (
//     <Navbar
//         className={`navbar ${checkbarStyle} `}>
//     </Navbar>);
// }
