import * as React from "react";
import axios from 'axios';
import { useState, useEffect } from 'react';
import "./inspection.css";

const ImageUpload = ({ className, file }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [responseText, setResponseText] = useState("No File Uploaded.");
    const [previewImage, setPreviewImage] = useState(null);
    const [url, setURL] = useState(null)

    const delay = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    };

   

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        if (file && file.type.startsWith('image/')) {
            setPreviewImage(URL.createObjectURL(file));
        } else {
            setPreviewImage(null);
        }
    };
    useEffect(() => {
        setURL(null)
        setPreviewImage(null)
    }, [])

    useEffect(() => {
        console.log("this is the file:" + file)
        if(file !== null) {
            setURL("https://potree-bucket.s3.us-east-2.amazonaws.com/images/" + file + ".png")
        }
            
       
    }, [file])


    useEffect(() => {
        if (url !== null) {
            handleAnalysisClick();
            setPreviewImage(url)
            console.log("This is the url " + url)
        }

    }, [url])



    useEffect(() => {
        if (selectedFile) {
            handleSubmit();
        }
    }, [selectedFile]);


    const handleAnalysisClick = async () => {
        if (!url) {
            return;
        }

        try {
            

            const response = await axios.post(
                'https://api.openai.com/v1/threads/runs',
                {
                    assistant_id: 'asst_sbUz4gcRzoEdcLHUJiPVXMYX',
                    thread: {
                        messages: [
                            {
                                role: 'user',
                                content: [
                                    {
                                        "type": "image_url",
                                        "image_url": {
                                            "url": url
                                        }
                                    },
                                    {
                                        "type": "text",
                                        "text": "Is the road in good condition?"
                                    }
                                ]
                            }
                        ]
                    }
                },
                {
                    headers: {
                        Authorization: `Bearer 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`,
                        'Content-Type': 'application/json',
                        'OpenAI-Beta': 'assistants=v2',
                    },
                }
            );

            const threadId = response.data.thread_id;
            const runId = response.data.id;

            let runResponse = await axios.get(
                `https://api.openai.com/v1/threads/${threadId}/runs/${runId}`,
                {
                    headers: {
                        Authorization: `Bearer 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`,
                        'OpenAI-Beta': 'assistants=v2',
                    },
                }
            );

            while (runResponse.data.status !== "completed") {
                runResponse = await axios.get(
                    `https://api.openai.com/v1/threads/${threadId}/runs/${runId}`,
                    {
                        headers: {
                            Authorization: `Bearer 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`,
                            'OpenAI-Beta': 'assistants=v2',
                        },
                    }
                );

                await delay(1000);
                setResponseText("Loading...");
            }

            const messageResponse = await axios.get(`https://api.openai.com/v1/threads/${threadId}/messages`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer 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`,
                    'OpenAI-Beta': 'assistants=v2',
                }
            });

            const finalResponse = messageResponse.data.data[0].content[0].text.value;
            setResponseText(finalResponse);

           
        } catch (error) {
            console.error('Error calling the OpenAI API:', error);
            setResponseText('An error occurred while processing your request.');
        }
    };
    

    const handleSubmit = async () => {
        if (!selectedFile) {
            return;
        }

        try {
            const response1 = await axios.post('https://api.openai.com/v1/files', {
                purpose: "vision",
                file: selectedFile
            }, {
                headers: {
                    Authorization: `Bearer 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`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            const fileId = response1.data.id;

            const response = await axios.post(
                'https://api.openai.com/v1/threads/runs',
                {
                    assistant_id: 'asst_sbUz4gcRzoEdcLHUJiPVXMYX',
                    thread: {
                        messages: [
                            {
                                role: 'user',
                                content: [
                                    {
                                        "type": "image_file",
                                        "image_file": {
                                            "file_id": fileId
                                        }
                                    },
                                    {
                                        "type": "text",
                                        "text": "Is the road in good condition?"
                                    }
                                ]
                            }
                        ]
                    }
                },
                {
                    headers: {
                        Authorization: `Bearer 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`,
                        'Content-Type': 'application/json',
                        'OpenAI-Beta': 'assistants=v2',
                    },
                }
            );

            const threadId = response.data.thread_id;
            const runId = response.data.id;

            let runResponse = await axios.get(
                `https://api.openai.com/v1/threads/${threadId}/runs/${runId}`,
                {
                    headers: {
                        Authorization: `Bearer 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`,
                        'OpenAI-Beta': 'assistants=v2',
                    },
                }
            );

            while (runResponse.data.status !== "completed") {
                runResponse = await axios.get(
                    `https://api.openai.com/v1/threads/${threadId}/runs/${runId}`,
                    {
                        headers: {
                            Authorization: `Bearer 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`,
                            'OpenAI-Beta': 'assistants=v2',
                        },
                    }
                );

                await delay(1000);
                setResponseText("Loading...");
            }

            const messageResponse = await axios.get(`https://api.openai.com/v1/threads/${threadId}/messages`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer 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`,
                    'OpenAI-Beta': 'assistants=v2',
                }
            });

            const finalResponse = messageResponse.data.data[0].content[0].text.value;
            setResponseText(finalResponse);

            await axios.delete(`https://api.openai.com/v1/files/${fileId}`, {
                headers: {
                    'Authorization': `Bearer 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`
                }
            });

        } catch (error) {
            console.error('Error calling the OpenAI API:', error);
            setResponseText('An error occurred while processing your request.');
        }
    };

    return (
        <div >
            <div className='title'>
                <h1>Road Image Analyzer</h1>
                <p>Insert an Image of a Road To Be Analyzed</p>
                <div className="analysis">
                    <strong>Analysis:</strong> {responseText}
                </div>
            </div>

            <div className="placeholder">
                {previewImage !== null ? (
                    <img src={previewImage} alt="Preview" className="preview-image" />
                ) : (
                    <img src={require('../images/image.png')} className="placeholder-image" />
                )}
            </div>

            <div>
                <input
                    type="file"
                    id="fileUpload"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                />
                <label htmlFor="fileUpload" className="upload-button">
                    {selectedFile ? 'Change Image' : 'Upload Image'}
                </label>
            </div>
        </div>
    );
};

export default ImageUpload;
