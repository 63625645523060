export const TIP_MESSAGES = {
    widthRW:
        'To what extent should road width impact the combined condition rating? Use a scale from 0 (low weight) to 1 (high weight).',
    pciRW: 'To what extent should the PCI (Pavement Condition Index) impact the combined condition rating? Use a scale from 0 (low weight) to 1 (high weight).',
    curbRW: 'To what extent should the curb existence impact the combined condition rating? Use a scale from 0 (low weight) to 1 (high weight).',
    sidewalkRW:
        'To what extent should sidewalk existence impact the combined condition rating? Use a scale from 0 (low weight) to 1 (high weight).',
    distanceW:
        'To what extent should the distance/road length impact the combined importance rating? Use a scale from 0 (low weight) to 1 (high weight).',
    populationW:
        'To what extent should the population distribution impact the combined importance rating? Use a scale from 0 (low weight) to 1 (high weight).',
    timeW: 'To what extent should the travel time impact the combined importance rating? Use a scale from 0 (low weight) to 1 (high weight).',
    widthW: 'To what extent should the road width (usually influenced by designation) impact the combined importance rating? Use a scale from 0 (low weight) to 1 (high weight).',
    SA01W: "To what extent should 'Jobs of workers age 29 or younger' impact the combined importance rating? Use a scale from 0 (low weight) to 1 (high weight).",
    SA02W: "To what extent should 'Jobs for workers age 30 to 54' impact the combined importance rating? Use a scale from 0 (low weight) to 1 (high weight).",
    SA03W: "To what extent should 'Jobs for workers age 55 or older' impact the combined importance rating? Use a scale from 0 (low weight) to 1 (high weight).",
    SE01W: "To what extent should 'Jobs with earnings $1250/month or less' impact the combined importance rating? Use a scale from 0 (low weight) to 1 (high weight).",
    SE02W: "To what extent should 'Jobs with earnings $1251/month to $3333/month' impact the combined importance rating? Use a scale from 0 (low weight) to 1 (high weight).",
    SE03W: "To what extent should 'Jobs with earnings greater than $3333/month' impact the combined importance rating? Use a scale from 0 (low weight) to 1 (high weight).",
    SI01W: "To what extent should 'Jobs in goods producing industry sectors' impact the combined importance rating? Use a scale from 0 (low weight) to 1 (high weight).",
    SI02W: "To what extent should 'Jobs in trade, transportation, and utilities industry sectors' impact the combined importance rating? Use a scale from 0 (low weight) to 1 (high weight).",
    SI03W: "To what extent should 'Jobs in all other services industry sectors' impact the combined importance rating? Use a scale from 0 (low weight) to 1 (high weight).",
    evac_importance_1:
        "To what extent should 'Primary evacuation roads' impact the combined importance rating? Use a scale from 0 (low weight) to 1 (high weight).",
    evac_importance_2:
        "To what extent should 'Secondary evacuation roads' impact the combined importance rating? Use a scale from 0 (low weight) to 1 (high weight).",
    maintain:
        'Filter hillside roads based on maintenance status (currently maintained or unmaintained)',
    location: 'Filter hillside roads based on location (north, central, south).',
    community:
        'Filter hillside roads based on priority group communities according to AB1550/SB535.',
    council: 'Filter hillside roads based on council district.',
    designation: 'Filter hillside roads based street designation.',
    uslope: 'To what extent should the uphill slope impact the the hazard rating? Use a scale from 0 (low weight) to 1 (high weight).',
    dslope: 'To what extent should the downhill slope impact the the hazard rating? Use a scale from 0 (low weight) to 1 (high weight).',
    landslide: 'Only show roads that are within landslide zones.',
    evac_time:
        'Filter hillside roads based on evacuation time. (Set minimum and maximum boundaries).',
    evac_overlap:
        'Filter hillside roads based on evacuation time overlap of primary and secondary evacuation path. (Set minimum and maximum boundaries)',
    inspection: 'Only show roads which were inspected and analyzed (See Collection).',
    inspW: 'Filter hillside roads by maximum inspected width.',
};
