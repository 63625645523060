import React from "react";
import { MenuItem, Select, FormControl, Stack, Chip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";

/*
Multi Select Component

* Extends select component to allow for multiple values
*/
export default function MultiSelect({
  options,
  setOptions,
  selectedOptions,
  callback,
}) {
  return (
    <FormControl sx={{ m: 1, maxWidth: 200 }} variant="filled">
      <Select
        multiple
        value={selectedOptions || []}
        onChange={(e) => {
          setOptions(e.target.value);
          callback();
        }}
        renderValue={(selected) => (
          <Stack gap={1} direction="row" flexWrap="wrap">
            {selected.map((value) => (
              <Chip
                key={value}
                label={value}
                onDelete={() => {
                  setOptions(selectedOptions.filter((item) => item !== value));
                  callback();
                }}
                deleteIcon={
                  <CancelIcon
                    onMouseDown={(event) => event.stopPropagation()}
                  />
                }
              />
            ))}
          </Stack>
        )}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            value={option}
            sx={{ justifyContent: "space-between" }}
          >
            {option}
            {selectedOptions && selectedOptions.includes(option) ? (
              <CheckIcon color="info" />
            ) : null}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
