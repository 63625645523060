import { useEffect, useState } from "react";
import Plot from "react-plotly.js";

/*
Scatter Plot 3D Component

* Provides access to visualize geojson data in a scatter plot
* Stores selection in selectionKey for portability
* Updates data visualization with highlighted data
*/
export default function ScatterPlot3D({
  geoData,
  width,
  height,
  yScale,
  xProperty,
  yProperty,
  zProperty,
  title,
  selection,
}) {
  const [x, setX] = useState([]);
  const [y, setY] = useState([]);
  const [z, setZ] = useState([]);
  const [t, setT] = useState([]);

  const [xSel, setXSel] = useState([]);
  const [ySel, setYSel] = useState([]);
  const [zSel, setZSel] = useState([]);
  const [tSel, setTSel] = useState([]);

  // Updates data when selection is made
  useEffect(() => {
    setX(
      geoData.map((d) => {
        return d.properties[xProperty];
      })
    );
    setY(
      geoData.map((d) => {
        return yScale === "log"
          ? Math.log(d.properties[yProperty])
          : d.properties[yProperty];
      })
    );
    setT(
      geoData.map((d) => {
        return d.properties.ST_NAME;
      })
    );
    setZ(
      geoData.map((d) => {
        return d.properties[zProperty];
      })
    );

    if (selection != null && selection.length > 0) {
      setXSel(
        selection.map((d) => {
          return d.properties[xProperty];
        })
      );
      setYSel(
        selection.map((d) => {
          return yScale === "log"
            ? Math.log(d.properties[yProperty])
            : d.properties[yProperty];
        })
      );
      setZSel(
        selection.map((d) => {
          return d.properties[zProperty];
        })
      );
      setTSel(
        selection.map((d) => {
          return d.properties.ST_NAME;
        })
      );
    } else {
      setXSel([]);
      setYSel([]);
      setZSel([]);
      setTSel([]);
    }
  }, [geoData, width, height, xProperty, yProperty, zProperty, selection]);

  return (
    <div>
      <Plot
        data={[
          {
            type: "scatter3d",
            mode: "markers",
            x: x,
            y: y,
            z: z,
            text: t,
            hovertemplate:
              "<b>%{text}</b><br><br>" +
              yProperty.charAt(0).toUpperCase() +
              yProperty.slice(1) +
              ": %{y}<br>" +
              xProperty.charAt(0).toUpperCase() +
              xProperty.slice(1) +
              ": %{x}<br>" +
              zProperty.charAt(0).toUpperCase() +
              zProperty.slice(1) +
              ": %{z}<br>" +
              "<extra></extra>",
            marker: {
              line: {
                color: "var(--dark-default)",
                width: 0.8,
              },
              size: 6,
            },
          },
          {
            type: "scatter3d",
            mode: "markers",
            x: xSel,
            y: ySel,
            z: zSel,
            text: tSel,
            hovertemplate:
              "<b>%{text}</b><br><br>" +
              yProperty.charAt(0).toUpperCase() +
              yProperty.slice(1) +
              ": %{y}<br>" +
              xProperty.charAt(0).toUpperCase() +
              xProperty.slice(1) +
              ": %{x}<br>" +
              zProperty.charAt(0).toUpperCase() +
              zProperty.slice(1) +
              ": %{z}<br>" +
              "<extra></extra>",
            marker: {
              line: {
                color: "var(--dark-default)",
                width: 0.8,
              },
              size: 6,
            },
          },
        ]}
        layout={{
          width: width,
          height: height,
          margin: {
            l: 60,
            r: 20,
            b: 40,
            t: 40,
            pad: 5,
          },
          scene: {
            xaxis: {
              title: xProperty.charAt(0).toUpperCase() + xProperty.slice(1),
            },
            yaxis: {
              title: yProperty.charAt(0).toUpperCase() + yProperty.slice(1),
            },
            zaxis: {
              title: zProperty.charAt(0).toUpperCase() + zProperty.slice(1),
            },
          },
          showlegend: false,
          title: title,
        }}
      />
    </div>
  );
}
